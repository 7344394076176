import { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Loader from "../../components/Loader";
import { INPT_LENGTH, formFieldsValidator, validatorFunctions } from "../helpers/board-helpers";
import { boardWrapperStl, stl, titleStl, inputSx, titleStlAsterics } from "./boardStl";
import { useCreateOrUpdateVirtualAccount, useGetAccountsQuery } from "../accountsApi";
import { SelectItemsWrapper } from "../../components/SelectItems/SelectItemsWrapper";

export default function AddSubAccountBoardHandler(props) {
  const [
    createOrUpdateVirtualAccount,
    { isLoading: isLoadingCreateVirtualAccount, isFetching: isFetchingCreateVirtualAccount, data, error },
  ] = useCreateOrUpdateVirtualAccount();

  const [isChecked, setIsChecked] = useState(false);
  const {
    data: accounts,
    isFetching: isFetchingGetAccounts,
    isLoading: isLoadingingGetAccounts,
    refetch: refetchGetAccounts,
  } = useGetAccountsQuery();

  const [accountsListWithItitialSelection, setAccountsListWithItitialSelection] = useState([]);
  const [canSubmit, setCanSubmit] = useState();
  const setSelectedItems = (newSelectedItems) => {
    setAccountsListWithItitialSelection(newSelectedItems);
  };

  useEffect(() => {
    if (accounts.list)
      setAccountsListWithItitialSelection(
        accounts.list
          ?.filter((a) => {
            return a.virtualAccount === 0;
          })
          ?.map((ac1) => {
            let isSelected =
              props?.value?.subAccountsData?.some((ac2) => {
                return ac1.id === ac2.id;
              }) || false;
            return { ...ac1, isSelected };
          })
      );
  }, [accounts.list]);

  const [formValues, setFormValues] = useState({
    company: {
      required: 1,
      value: props.value.company ? props.value.company : "",
      error: false,
      errorMessage: "Please, add account name",
      validationFunction: [(val) => !!val.trim().length],
    },
    firstName: {
      required: props.value.showUserSection ? 1 : 0,
      value: "",
      error: false,
      errorMessage: "Please, enter user's name (as account administrator) (" + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.firstName,
    },
    lastName: {
      required: props.value.showUserSection ? 1 : 0,
      value: "",
      error: false,
      errorMessage: "Please, enter user's last name (as account administrator)(" + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.lastName,
    },
    email: {
      required: props.value.showUserSection ? 1 : 0,
      value: "",
      error: false,
      errorMessage: "Please, add an email addres in correct format",
      validationFunction: validatorFunctions.email,
    },
    phone: {
      required: props.value.showUserSection ? 1 : 0,
      value: "",
      error: false,
      errorMessage: "Please, add phone num. (+XXXXXXXXXXXX ('+' is optional))",
      validationFunction: validatorFunctions.phone,
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: { ...formValues[name], value, error: false },
    });
  };
  let mandatoryFields = ["company"];
  if (props.value.showUserSection) mandatoryFields = mandatoryFields.concat(["firstName", "lastName", "email"]);
  useEffect(() => {
    setCanSubmit(
      accountsListWithItitialSelection?.length > 0 &&
        mandatoryFields.every((field) => formValues[field]?.value.length > 0)
    );
  }, [formValues, accountsListWithItitialSelection]);

  const handleRetrieve = async () => {
    let { newFormValues, errors } = formFieldsValidator(formValues, isChecked);
    if (!!errors.length) {
      setFormValues(newFormValues);
      return;
    }
    let data = {
      company: formValues.company.value,
    };

    if (props.value.showUserSection) {
      data.userData = [
        {
          firstName: formValues.firstName.value,
          lastName: formValues.lastName.value,
          phone: formValues.phone.value,
          email: formValues.email.value,
          position: "Multi-account admin",
        },
      ];
    }

    let currentIds = accountsListWithItitialSelection.filter((i) => i.isSelected).map((i) => i.id);
    data.ids = currentIds;

    createOrUpdateVirtualAccount({
      data,
      includeUserSection: props.value.showUserSection,
      id: props.value?.id,
    }).then((data) => {
      if (data.error) {
        console.log(data.error);
        let strError = data.error;
        if ("message" in data.error.data) {
          strError = data.error.data.message.join();
        }
        alert("Action failed, something went wrong..." + strError);
      } else {
        refetchGetAccounts();
        props.onClose();
      }
    });
  };

  if (
    isFetchingGetAccounts ||
    isLoadingingGetAccounts ||
    isLoadingCreateVirtualAccount ||
    isFetchingCreateVirtualAccount
  )
    return <Loader sx={{ height: "80vh" }} />;

  return (
    <div id="edit-subaccount-popup">
      <div style={boardWrapperStl}>
        {/* Account name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Multi-account name
          </Box>
          <TextField
            disabled={!props.value.showUserSection}
            id="partner-input"
            sx={inputSx}
            onChange={handleChange}
            error={formValues.company.error}
            helperText={formValues.company.error && formValues.company.errorMessage}
            value={formValues.company.value}
            placeholder="Company name"
            name="company"
            autoFocus
          />
        </Box>
        <Box id="select-account-field" sx={{ ...titleStl, ...titleStlAsterics, marginBottom: "1rem" }}>
          Select account from the list to add to Multi-account:
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
          {accountsListWithItitialSelection && (
            <SelectItemsWrapper
              id="account-select"
              itemName="account"
              itemFieldName="company"
              items={accountsListWithItitialSelection}
              setSelectedItems={setSelectedItems}
            />
          )}
        </Box>
        <br></br>

        {props.value.showUserSection ? (
          <>
            <Box id="administrator-subtitle">Account administrator details</Box>
            <Box sx={stl}>
              <Box
                sx={{
                  ...titleStl,
                  ...titleStlAsterics,
                }}
              >
                First name
              </Box>
              <TextField
                id="first-name-input"
                sx={inputSx}
                onChange={handleChange}
                error={formValues.firstName.error}
                helperText={formValues.firstName.error && formValues.firstName.errorMessage}
                value={formValues.firstName.value}
                placeholder="First name"
                name="firstName"
              />
            </Box>

            <Box sx={stl}>
              <Box
                sx={{
                  ...titleStl,
                  ...titleStlAsterics,
                }}
              >
                Last name
              </Box>
              <TextField
                id="last-name-input"
                sx={inputSx}
                onChange={handleChange}
                error={formValues.lastName.error}
                helperText={formValues.lastName.error && formValues.lastName.errorMessage}
                value={formValues.lastName.value}
                name="lastName"
                placeholder="Last name"
              />
            </Box>

            <Box sx={stl}>
              <Box sx={titleStl}>Phone number</Box>
              <TextField
                id="phone-input"
                sx={inputSx}
                placeholder="+XXXXXXXXXXXX ('+' is optional)"
                name="phone"
                value={formValues.phone.value}
                onChange={handleChange}
                error={formValues.phone.error}
                helperText={formValues.phone.error && formValues.phone.errorMessage}
              />
            </Box>

            <Box sx={stl}>
              <Box
                sx={{
                  ...titleStl,
                  ...titleStlAsterics,
                }}
              >
                Email
              </Box>
              <TextField
                id="email-input"
                sx={inputSx}
                placeholder="Enter user's email"
                name="email"
                required
                value={formValues.email.value}
                onChange={handleChange}
                error={formValues.email.error}
                helperText={formValues.email.error && formValues.email.errorMessage}
              />
            </Box>
          </>
        ) : null}
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <Button id="save-btn" disabled={!canSubmit} variant="contained" onClick={handleRetrieve}>
          Save changes
        </Button>
      </div>
    </div>
  );
}
