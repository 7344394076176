import { colors } from "../../theme";

interface IconProps {
  color?: string;
}

const IQLogoSidebarClose: React.FC<IconProps> = ({ color }) => {
  const fillColor = color ?? colors.brandDefault;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="40" viewBox="0 0 24 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.525594 10.06C0.294809 10.1484 0.117849 10.3343 0.0552339 10.554C-0.0192592 10.8156 -0.0180012 28.7806 0.0565815 29.0424C0.121169 29.2691 0.345417 29.4839 0.589648 29.5531C0.708111 29.5866 1.18868 29.5998 1.94611 29.5904C3.30115 29.5735 3.38461 29.5522 3.58972 29.1712L3.70177 28.9631V19.7959V10.6287L3.58972 10.4206C3.51827 10.2878 3.41267 10.1768 3.29838 10.1142C3.13133 10.0227 3.03648 10.0152 1.90891 10.0048C0.959598 9.99608 0.661403 10.008 0.525594 10.06ZM13.2711 10.0445C10.03 10.4293 7.18295 12.6002 5.81612 15.729C5.23246 17.065 4.98092 18.2899 4.98092 19.7959C4.98092 21.3019 5.23246 22.5268 5.81612 23.8628C7.04432 26.6743 9.52392 28.7696 12.3529 29.3864C14.6629 29.8901 17.0713 29.4713 19.0377 28.2239L19.4481 27.9636L20.2479 28.8072C20.9423 29.5398 21.0916 29.6738 21.3815 29.8255C21.7001 29.9921 21.7359 30 22.1682 30C22.6014 30 22.6358 29.9923 22.9625 29.8225C23.6881 29.4454 24.0961 28.6571 23.9806 27.8555C23.8928 27.2457 23.7762 27.0623 22.8533 26.0814L22.0487 25.2265L22.1734 25.021C22.9981 23.6622 23.4618 22.1519 23.5849 20.424C23.7492 18.1161 23.0721 15.7639 21.6915 13.8469C21.3321 13.3478 20.4367 12.4056 19.9648 12.0298C18.5514 10.9044 16.9583 10.2347 15.2338 10.0412C14.7317 9.98491 13.7597 9.98656 13.2711 10.0445ZM15.4001 14.0206C17.6909 14.5277 19.4281 16.424 19.8208 18.8463C19.8891 19.2681 19.8879 20.3029 19.8185 20.7385C19.7377 21.2456 19.4506 22.2035 19.3398 22.3358C19.3314 22.3459 19.1381 22.1677 18.9104 21.9398C18.656 21.6853 18.4063 21.4821 18.263 21.4131C17.5414 21.0658 16.7259 21.2106 16.1819 21.7827C15.7488 22.238 15.5619 22.8281 15.6541 23.4486C15.7228 23.9103 15.87 24.181 16.3164 24.6662L16.7232 25.1084L16.3978 25.2512C14.7756 25.9634 12.776 25.7749 11.2806 24.7689C9.95099 23.8746 9.01574 22.387 8.7663 20.7698C8.3699 18.1996 9.58609 15.688 11.7949 14.5156C12.1893 14.3062 12.9304 14.056 13.3895 13.9771C13.8954 13.8902 14.9102 13.9122 15.4001 14.0206Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IQLogoSidebarClose;
