import { colors } from "../../theme";

interface IconProps {
  color?: string;
}

const DoubleArrowRightIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color ?? colors.brandDefault;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.97893 9.99984L4.74977 6.74984C4.59699 6.59706 4.51713 6.40609 4.51018 6.17692C4.50324 5.94775 4.5831 5.74984 4.74977 5.58317C4.90254 5.43039 5.09699 5.354 5.3331 5.354C5.56921 5.354 5.76365 5.43039 5.91643 5.58317L9.74977 9.4165C9.8331 9.49984 9.89213 9.59012 9.92685 9.68734C9.96157 9.78456 9.97893 9.88873 9.97893 9.99984C9.97893 10.1109 9.96157 10.2151 9.92685 10.3123C9.89213 10.4096 9.8331 10.4998 9.74977 10.5832L5.91643 14.4165C5.76365 14.5693 5.57268 14.6491 5.34352 14.6561C5.11435 14.663 4.91643 14.5832 4.74977 14.4165C4.59699 14.2637 4.5206 14.0693 4.5206 13.8332C4.5206 13.5971 4.59699 13.4026 4.74977 13.2498L7.97893 9.99984ZM13.4789 9.99984L10.2498 6.74984C10.097 6.59706 10.0171 6.40609 10.0102 6.17692C10.0032 5.94775 10.0831 5.74984 10.2498 5.58317C10.4025 5.43039 10.597 5.354 10.8331 5.354C11.0692 5.354 11.2637 5.43039 11.4164 5.58317L15.2498 9.4165C15.3331 9.49984 15.3921 9.59012 15.4268 9.68734C15.4616 9.78456 15.4789 9.88873 15.4789 9.99984C15.4789 10.1109 15.4616 10.2151 15.4268 10.3123C15.3921 10.4096 15.3331 10.4998 15.2498 10.5832L11.4164 14.4165C11.2637 14.5693 11.0727 14.6491 10.8435 14.6561C10.6143 14.663 10.4164 14.5832 10.2498 14.4165C10.097 14.2637 10.0206 14.0693 10.0206 13.8332C10.0206 13.5971 10.097 13.4026 10.2498 13.2498L13.4789 9.99984Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default DoubleArrowRightIcon;
