import { createSlice } from "@reduxjs/toolkit";
import {
  convertRawData,
  getValuesFromRawDataForWidgets,
  convertToValues,
  prepareLineChartsForWidgetBoxes,
  convertDataRawDataForWidgetsPreviousPeriod,
  getReportValues,
  prepareReportDates,
  getPercentageDifference,
} from "./utils";
import { getReportPreviousPeriodReducer } from "./reportReducers/getReportPreviousPeriodReducer";
import { getReportReducer } from "./reportReducers/getReportReducer";
import { getSummaryReducer } from "./reportReducers/getSummaryReducer";
import { convertedBrowsersIds } from "./report-helpers";
import { getSummaryReport } from "../summary/summaryThunks/getSummaryReport";
import { getReport } from "./reportThunks/getReport";
import { getReportPreviousPeriod } from "./reportThunks/getReportPreviousPeriod";
import { getReportByPlacement } from "./reportThunks/getReportByPlacement";
import { getReportPreviousPeriodByPlacement } from "./reportThunks/getReportPreviousPeriodByPlacement";
import { getSummary } from "./reportThunks/getSummary";
import { calcCustomDateforPreviousReport } from "../utils";
import { CONSTANT } from "../constants/constants";

const initialState = {
  recs: [],
  units: [],
  unit: [],
  filterBase: CONSTANT.OVERVIEW.CHART_BASES.SUMMARY,
  active_chart: CONSTANT.OVERVIEW.CHART_TYPES.SUMMARY,
  status: "idle",
  error: null,
  processing: false,
  reporterType: CONSTANT.REPORTER_TYPES.IIQ_SSDK,
  // statsRaw: [],
  currentPeriodSummaryValues: {},
  previousPeriodSummaryRawData: [],
  previousPeriodSummaryValues: {},
  summaryCompleteData: {
    currentPeriodSummaryValues: null,
    previousPeriodSummaryValues: null,
  },
  widgetDataProcessing: false,
  widgetDataStatus: "idle",
  widgetDataCurrentPerodProcessing: false,
  widgetDataCurrentPerodDataStatus: "idle",
  widgetDataPrevPerodProcessing: false,
  widgetDataPrevPerodDataStatus: "idle",
  customDateRangePreviousPeriod: {
    customDateStart: null,
    customDateEnd: null,
  },
  clutterRemovalPercentage: {
    crpWithIIQ: 0.001,
    crpWithoutIIQ: 0.001,
    deselectionPercentage: 0.5,
  },
  // currency: "USD",
  rawCurrency: [],
  rawPct: [],
  selectedPct: null,
  selectedCurrency: null,
  widgetChartPreviousPeriodData: {},
  widgetChartCurrentData: {},
  comparisonResult: {},
  uniqPairs: [],
  apt: 0,
  settingsStrictMode: true,
  isMissingSettings: [],
};

let arrOfKeysPlacement = ["submitted_date", "client_type", "site_id", "biddercode", "placement_id"];

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportEntryParametersConstancy(state, action) {
      if (action.payload && Object.keys(action.payload).length) {
        const { chartBaseUnits, chartBase, chartType, settingsStrictMode } = action.payload.overviewParameters;
        state.active_chart = chartType;
        if (chartBaseUnits && Object.keys(chartBaseUnits).length) {
          state.units = chartBaseUnits[chartBase].units;
          state.unit = chartBaseUnits[chartBase].unit;
        }
        state.filterBase = chartBase;
        // state.selectedPct = selectedPct;
        // state.selectedCurrency = selectedCurrency;
        state.settingsStrictMode = settingsStrictMode;
      }
    },
    setActiveChart(state, action) {
      state.active_chart = action.payload;
    },
    setSettingsStrictMode(state, action) {
      state.settingsStrictMode = action.payload;
    },
    setIsMissingSettings(state, action) {
      state.isMissingSettings = action.payload;
    },
    resetReport(state) {
      return {
        ...initialState,
        apt: state.apt,
      };
    },
    setActiveBase(state, action) {
      state.filterBase = action.payload;
    },
    resetCurrencyPct(state) {
      state.selectedCurrency = null;
      state.selectedPct = null;
    },
    setSummaryChartAsActiveChart(state) {
      state.unit = [];
      state.active_chart = CONSTANT.OVERVIEW.CHART_TYPES.SUMMARY;
      state.filterBase = CONSTANT.OVERVIEW.CHART_BASES.SUMMARY;
    },
    setClutterRemovalData(state, action) {
      state.clutterRemovalPercentage.crpWithIIQ = action.payload.clutterRemovalPercetageWithIIQ;
      state.clutterRemovalPercentage.deselectionPercentage = action.payload.defaultFiltrationDeselection;
    },
    filterByUnit(state, action) {
      state.isMissingSettings = [];
      state.unit = action.payload;
    },
    applyPctMode(state, action) {
      let data = action.payload.split("-");
      state.selectedCurrency = data[0];
      state.selectedPct = +data[1];
    },
    setCurrencyPctModeData(state, action) {
      let { pctModes, currencyModes, uniqPairs, shouldUpdateSelected, maxCurrencyPctCombination } = action.payload;

      if (shouldUpdateSelected) {
        state.selectedCurrency = uniqPairs[0].currency;
        state.selectedPct = uniqPairs[0].pct;
      }
      state.selectedPct = maxCurrencyPctCombination.pct;
      state.selectedCurrency = maxCurrencyPctCombination.currency;
      state.uniqPairs = uniqPairs;
      state.rawPct = pctModes;
      state.rawCurrency = currencyModes;
      state.selectedCurrency = maxCurrencyPctCombination.currency;
      state.selectedPct = maxCurrencyPctCombination.pct;
    },
    getTopUnits(state) {
      //get N units, based on sorted ab_group_type chart
      state.isMissingSettings = [];

      if (state.units.length <= 5) {
        state.unit = state.units;
        return;
      }

      let activeChart = state.active_chart;
      let field = "gr_a_cash";
      if (activeChart === 2 || activeChart === 3) field = "gr_a";

      let names = state.recs.ab_group_type
        .slice()
        .sort((a, b) => b[field] - a[field])
        .slice(0, 5) //get top 5
        .map((i) => i.name);

      const updatedUnits = state.units.filter((i) => names.includes(i.partnerInstaceId));
      state.unit = updatedUnits;
    },
    setSummaryCompleteDate(state) {
      if (!state.summaryCompleteData.currentPeriodSummaryValues) return;
      state.previousPeriodSummaryValues = state.summaryCompleteData.previousPeriodSummaryValues;
      state.currentPeriodSummaryValues = state.summaryCompleteData.currentPeriodSummaryValues;
      //* original values for charts inside widgets
      state.widgetChartCurrentData = state.summaryCompleteData.widgetChartDataCurPeriod;
      state.widgetChartPreviousPeriodData = state.summaryCompleteData.widgetChartDataPrevPeriod;
      let comparisonResult = {};
      for (let i in state.previousPeriodSummaryValues) {
        comparisonResult[i] = getPercentageDifference(
          state.previousPeriodSummaryValues[i],
          state.currentPeriodSummaryValues[i]
        );
      }
      state.comparisonResult = comparisonResult;
    },
    resetSummaryCompleteDate(state) {
      // state.unit = [];
      state.summaryCompleteData.previousPeriodSummaryValues = {};
      state.summaryCompleteData.currentPeriodSummaryValues = {};
      state.currentPeriodSummaryValues = {};
      state.previousPeriodSummaryValues = {};
    },
    resetStatusWidgetProcessing(state) {
      state.status = "idle";
      state.active_chart = CONSTANT.OVERVIEW.CHART_TYPES.SUMMARY;
      state.filterBase = CONSTANT.OVERVIEW.CHART_BASES.SUMMARY;
      state.error = null;
      state.processing = false;
      state.widgetDataCurrentPerodProcessing = false;
      state.widgetDataCurrentPerodDataStatus = "idle";
      state.widgetDataPrevPerodProcessing = false;
      state.widgetDataPrevPerodDataStatus = "idle";
    },
    resetRawPctAndRawCurrencyIndicators(state) {
      state.rawCurrency = [];
      state.rawPct = [];
      // state.selectedPct = null;
      // state.selectedCurrency = null;
      state.uniqPairs = [];
    },
    setReplaced(state, action) {
      state.apt = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getReport.pending, (state) => {
        // state.status = "loading";
        state.processing = true;
        state.widgetDataCurrentPerodProcessing = true;
      })
      .addCase(getReport.fulfilled, getReportReducer)
      .addCase(getReport.rejected, (state, action) => {
        if (action.payload === "report not found") {
          state.status = "not_found";
          state.error = action.payload;
        } else if (action.payload === "service_unavailable") {
          state.status = 503;
          state.error = action.payload;
        } else if (action.payload === "forbidden_resource") {
          state.status = 403;
          state.error = action.payload;
          state.processing = false;
          state.widgetDataCurrentPerodProcessing = false;
        } else {
          state.processing = false;
          state.status = "failed";
          state.error = action.payload;
          // state.error = action.error.message;
        }
      });

    builder
      .addCase(getReportPreviousPeriod.pending, (state) => {
        state.widgetDataPrevPerodProcessing = true;
      })
      .addCase(getReportPreviousPeriod.fulfilled, getReportPreviousPeriodReducer)
      .addCase(getReportPreviousPeriod.rejected, (state, action) => {
        if (action.payload === "report not found") {
          state.status = "not_found";
          state.error = action.payload;
        } else {
          state.processing = false;
          state.status = "failed";
          state.error = action.payload;
          // state.error = action.error.message;
        }
      });

    builder
      .addCase(getReportByPlacement.pending, (state) => {
        state.processing = true;
      })
      .addCase(getReportByPlacement.fulfilled, (state, action) => {
        state.processing = false;
        state.widgetDataCurrentPerodProcessing = false;
        // Conver raw data to appropriate object

        if (!action.payload.result.data.length) {
          state.filterBase = "placement_id";
          state.recs = [];

          state.active_chart = action.payload.activeChart;
          state.units = [];
          state.unit = [];
          // state.statsRaw = [];
          // state.status = "idle";
          return;
        }

        let { stats, activeRangeDate, pct, base } = getReportValues(action, "placement_id");
        let chartData = [];
        let categories = [];

        //get proper dates
        let { reportDateStart, chartLastDate } = prepareReportDates(activeRangeDate, action, state);

        //Widget chart
        let widgetChartDataCurPeriod = prepareLineChartsForWidgetBoxes(
          stats,
          reportDateStart.startDate,
          chartLastDate,
          pct
        );

        // Calculate and Convert raw data to Widget's appropiate object , calc levelOfAllowableValues - to ignore very low or minor values (based on SUM(total_rows1))
        let agregatedValues = getValuesFromRawDataForWidgets(stats);
        let levelOfAllowableValues =
          (agregatedValues.total_rows1 * state.clutterRemovalPercentage.crpWithIIQ) / 100;
        if (action.payload.skipSetBaseAsActive === false && "filterBase" in action.payload) {
          state.filterBase = action.payload.filterBase;
        }

        if (stats.length) {
          chartData = convertRawData(
            stats,
            "placement_id",
            reportDateStart.startDate,
            chartLastDate,
            action.payload.activeChart,
            arrOfKeysPlacement,
            levelOfAllowableValues
          );
          categories = getAvaliableUnit(chartData.lift_type[0], chartData.keyValuePairs);
        }

        state.filterBase = "placement_id";
        state.recs = chartData;
        // state.currency = chartData.currency;
        // state.rawCurrency = chartData.rawCurrency;
        state.rawPct = chartData.rawPct;

        state.active_chart = action.payload.activeChart;
        state.units = categories;
        state.unit = categories;

        let preselectedValues = [];
        if (state.clutterRemovalPercentage.deselectionPercentage) {
          //for chart 1 - revenue - total_cpm1
          if (action.payload.activeChart === 1 || action.payload.activeChart === 0) {
            let levelOfPreselctedValues =
              (agregatedValues.total_cpm1 * state.clutterRemovalPercentage.deselectionPercentage) / 100;
            let names = chartData.ab_group_type.map((i) => i.gr_a_cash > levelOfPreselctedValues && i.name);
            preselectedValues = categories.filter((i) => names.includes(i.partnerInstaceId));
          }
          if (action.payload.activeChart === 2) {
            let levelOfPreselctedValues =
              (agregatedValues.total_rows1 * state.clutterRemovalPercentage.deselectionPercentage) / 100;
            let names = chartData.ab_group_type.map((i) => i.gr_a_imp > levelOfPreselctedValues && i.name);
            preselectedValues = categories.filter((i) => names.includes(i.partnerInstaceId));
          }
        }
        state.unit = preselectedValues.length > 0 ? preselectedValues : categories;
        state.unit = preselectedValues.length > 0 ? preselectedValues : categories;

        if (chartData.excludedValues.length > 0) {
          //remove from rawData excluded values
          let ids = chartData.excludedValues.map((i) => i.name);
          let res = stats.filter((i) => {
            return !ids.includes(i[base]);
          });
          agregatedValues = getValuesFromRawDataForWidgets(res);
        }

        // state.currentSummaryRawData = agregatedValues; //seems this is not in usage
        state.currentPeriodSummaryValues = convertToValues(agregatedValues, pct);
        state.summaryCompleteData.currentPeriodSummaryValues = state.currentPeriodSummaryValues;
        state.widgetChartCurrentData = widgetChartDataCurPeriod;

        //reset raw data of general table
        // state.statsRaw = [];
        state.status = "idle";
      })
      .addCase(getReportByPlacement.rejected, (state, action) => {
        if (action.payload === "report not found") {
          // state.status = "not_found";
          state.error = action;
        } else {
          state.processing = false;
          // state.status = "failed";
          state.error = action;
        }
      });

    builder
      .addCase(getReportPreviousPeriodByPlacement.pending, (state) => {
        state.widgetDataPrevPerodProcessing = true;
      })
      .addCase(getReportPreviousPeriodByPlacement.fulfilled, (state, action) => {
        state.widgetDataPrevPerodProcessing = true;

        if (!action.payload.result.data.length) {
          state.previousPeriodSummaryRawData = [];
          state.previousPeriodSummaryValues = {};
          // state.summaryCompleteData.previousPeriodSummaryValues = {};
          // state.widgetChartPreviousPeriodData = {};
          return;
        }
        let { stats, activeRangeDate, pct, base } = getReportValues(action, "placement_id");

        //get proper dates
        let { reportDateStart, chartLastDate } = prepareReportDates(activeRangeDate, action, state, true);
        // Widget chart
        let { agregatedValues } = convertDataRawDataForWidgetsPreviousPeriod(
          stats,
          state.clutterRemovalPercentage.crpWithIIQ,
          base
        );
        let widgetChartDataPrevPeriod = prepareLineChartsForWidgetBoxes(
          stats,
          reportDateStart.startDate,
          chartLastDate,
          pct
        );

        //get agregated values, calculate for widgets and add to store
        state.previousPeriodSummaryRawData = agregatedValues;
        state.previousPeriodSummaryValues = convertToValues(agregatedValues, pct);
        state.summaryCompleteData.previousPeriodSummaryValues = state.previousPeriodSummaryValues;
        state.widgetChartPreviousPeriodData = widgetChartDataPrevPeriod;
      })
      .addCase(getReportPreviousPeriodByPlacement.rejected, (state, action) => {
        if (action.payload === "report not found") {
          state.status = "not_found";
          state.error = action.payload;
        } else {
          state.processing = false;
          state.status = "failed";
          state.error = action.payload;
          // state.error = action.error.message;
        }
      });

    builder
      .addCase(getSummary.pending, (state) => {
        state.widgetDataProcessing = true;
      })
      .addCase(getSummary.fulfilled, getSummaryReducer)
      .addCase(getSummary.rejected, (state, action) => {
        if (action.payload === "report not found") {
          state.error = action.payload;
        } else {
          state.widgetDataProcessing = false;
          state.error = action.payload;
        }
      });

    builder.addCase(getSummaryReport.fulfilled, (state, action) => {
      const data = action.payload;
      if (!data.length) {
        state.summaryCompleteData = { previousPeriodSummaryValues: null, currentPeriodSummaryValues: null };

        return;
      }
      //data to area chart
      // if data for area chart comes first  - apply pct to reports slice
      let pctModes = action.payload.reportRawPct;
      let uniqPairs = action.payload.uniqPairs;

      state.uniqPairs = uniqPairs;
      state.rawPct = pctModes;
      // state.dateGroupingMode = action.payload.dateGroupingMode;
      if (
        "customDateRange" in action.payload &&
        action.payload.activeRangeDate === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value
      ) {
        let { customDateStart, customDateEnd } = action.payload.customDateRange;
        //set previous reported period
        let resPrevPeriod = calcCustomDateforPreviousReport(
          customDateStart,
          customDateEnd,
          action.payload.dateGroupingMode
        );
        state.customDateRangePreviousPeriod.customDateStart = resPrevPeriod.customDateStart;
        state.customDateRangePreviousPeriod.customDateEnd = resPrevPeriod.customDateEnd;
      }
    });
  },
});

export const {
  setReportEntryParametersConstancy,
  filterByUnit,
  setActiveChart,
  setActiveBase,
  setSummaryChartAsActiveChart,
  setSummaryCompleteDate,
  resetSummaryCompleteDate,
  resetRawPctAndRawCurrencyIndicators,
  setClutterRemovalData,
  getTopUnits,
  applyPctMode,
  setCurrencyPctModeData,
  resetStatusWidgetProcessing,
  setReplaced,
  resetReport,
  setSettingsStrictMode,
  setIsMissingSettings,
  resetCurrencyPct,
} = reportSlice.actions;

export default reportSlice.reducer;

export const getAvaliableUnit = (data, keyValPairs) => {
  let res = [];
  let count = 1;
  for (let i in data) {
    if (i === "date" || i === "nameMonth" || i === "dateAsStr") continue;
    let unitId = null;
    for (let [k, val] of keyValPairs) {
      if (k === i) unitId = val;
    }
    res.push({
      partnerInstaceId: i,
      color:
        convertedBrowsersIds[count]?.color ??
        `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`,
      clientItemName: i,
      unitId,
    });
    count++;
  }
  return res;
};
