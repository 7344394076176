import { useSelector } from "react-redux";
import { XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, LineChart, Line } from "recharts";
import { CustomizedAxisTick } from "../../../../../components/chartTemplates/utils";
import { CustomizedDot } from "../../../../../components/chartTemplates/lineChart-utils";
import { Box, Typography } from "@mui/material";
import { colors, graphColors } from "../../../../../theme";
import getSymbolFromCurrency from "currency-symbol-map";
import { CONSTANT } from "../../../../../constants/constants";
import { selectionChartTypeItems } from "./AreaChartWrapper";
import { useState } from "react";
import { CustomTooltip, CustomTooltipPerPartner, HoverTooltip } from "./CustomAreaChartTooltips";

const LINE_COLORS = Object.values(graphColors);
export const LINE_COLORS_CURRENCIES_MAP = {};

const transformDataForChart = (data, activeAreaChartType, currenciesToDisplay) => {
  const transformedData = {};

  const isImpressions = activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id;
  const isFillRate = activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id;

  Object.keys(data).forEach((partner) => {
    const partnerData = data[partner][activeAreaChartType];
    partnerData.forEach((dateData) => {
      const { date } = dateData;
      if (!transformedData[date]) {
        transformedData[date] = { date };
      }
      if (isImpressions) {
        // For impressions, sum up all currencies
        const totalImpressions = Object.keys(currenciesToDisplay).reduce((sum, currency) => {
          return sum + (dateData[currency] || 0);
        }, 0);
        transformedData[date][partner] = totalImpressions;
      } else if (isFillRate) {
        // For fill rate, summarize totalImpressionsA and totalImpressionsBNorm by currency
        const totalImpressionsA = Object.keys(currenciesToDisplay).reduce((sum, currency) => {
          return sum + (dateData[currency]?.totalImpressionsA || 0);
        }, 0);
        const totalImpressionsBNorm = Object.keys(currenciesToDisplay).reduce((sum, currency) => {
          return sum + (dateData[currency]?.totalImpressionsBNorm || 0);
        }, 0);
        // Calculate fill rate
        const fillRate = totalImpressionsBNorm ? (totalImpressionsA / totalImpressionsBNorm - 1) * 100 : 0;
        transformedData[date][partner] = fillRate;
      } else {
        // For other chart types, keep separate currency data
        Object.keys(currenciesToDisplay).forEach((currency) => {
          if (currenciesToDisplay[currency]) {
            const key = `${partner}${CONSTANT.COMBINED_STRING_KEY2}${currency}`;
            transformedData[date][key] = dateData[currency] || 0;
          }
        });
      }
    });
  });
  return Object.values(transformedData);
};
const agregateImpressionsForSummaryChart = (data, currenciesToDisplay) => {
  const currencies = Object.keys(currenciesToDisplay);
  return Object.values(
    data.reduce((acc, item) => {
      const dateKey = item.date; // Use date as a unique key

      if (!acc[dateKey]) {
        acc[dateKey] = {
          CURRENCY: 0,
          date: item.date,
          submitted_date: item.submitted_date,
        };
      }

      // Sum up the values for all currencies in currenciesToDisplay
      currencies.forEach((currency) => {
        if (item[currency]) {
          acc[dateKey].CURRENCY += item[currency];
        }
      });

      return acc;
    }, {})
  );
};

const makeChartColors = (activeAreaChartTypes, data, rawCurrency) => {
  const chartColors = {};
  activeAreaChartTypes.forEach((chartType) => {
    const isImpressions = chartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id;
    const isFillRate = chartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id;

    chartColors[chartType] = {};

    if (isImpressions || isFillRate) {
      // For impressions and fill rate, assign colors to partners
      Object.keys(data).forEach((partner, index) => {
        chartColors[chartType][partner] = LINE_COLORS[index % LINE_COLORS.length];
      });
    } else {
      // For other chart types, assign colors to partner-currency combinations
      Object.keys(data).forEach((partner, index) => {
        rawCurrency.forEach((currency, currencyIndex) => {
          const key = `${partner}${CONSTANT.COMBINED_STRING_KEY2}${currency}`;
          chartColors[chartType][key] =
            LINE_COLORS[(index * rawCurrency.length + currencyIndex) % LINE_COLORS.length];
        });
      });
    }
  });
  return chartColors;
};

const CustomLegend = ({ currencies, onClick, currenciesToDisplay }) => {
  return (
    <Box id="area-chart-legend" sx={{ display: "flex", marginTop: "-12px", flexWrap: "wrap" }}>
      {currencies.map((currency, index) => {
        const isActive = currenciesToDisplay[currency];
        const color = isActive ? LINE_COLORS_CURRENCIES_MAP[currency] : "#ccc"; // Grey color for inactive currencies
        return (
          <span
            id="area-chart-legend-item"
            key={"custom_legend_" + index}
            onClick={() => onClick(currency)}
            style={{
              cursor: "pointer",
              marginRight: 20,
              display: "inline-flex",
              alignItems: "center",
              marginBottom: "6px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: color,
                marginRight: 5,
              }}
            ></span>
            {getSymbolFromCurrency(currency) || currency}
          </span>
        );
      })}
    </Box>
  );
};

const AreaChartWidget = (props) => {
  const { selectedCurrency: currency, apt } = useSelector((state) => state.report);
  const { isPresentationModeEnabled } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );

  let { rawCurrency } = useSelector((state) => state.advancedReport);
  const { activeAreaChartTypes, currenciesToDisplay, isSummary } = props;

  // const [fixedTooltips, setFixedTooltips] = useState([]);

  let isFullSize = activeAreaChartTypes.length === 1; //depends on count of activeAreaChartTypes
  let itemLastIndex = activeAreaChartTypes[activeAreaChartTypes.length - 1];

  const { dateRange, dateGroupingMode } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const timeGrouping = dateRange.value;
  rawCurrency.forEach((currency, index) => {
    LINE_COLORS_CURRENCIES_MAP[currency] = LINE_COLORS[index];
  });

  const dataBySelectedAggregationType = isSummary ? props.data.summary : props.data.perPartner;
  let chartColors = {};
  if (!isSummary) {
    chartColors = makeChartColors(activeAreaChartTypes, dataBySelectedAggregationType, rawCurrency);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
        marginTop: "12px",
      }}
    >
      {activeAreaChartTypes?.map((activeAreaChartType) => {
        const chartItem = selectionChartTypeItems.find((item) => item.id === activeAreaChartType);

        if (chartItem) {
          let typedData = isSummary
            ? dataBySelectedAggregationType[activeAreaChartType]
            : transformDataForChart(dataBySelectedAggregationType, activeAreaChartType, currenciesToDisplay);
          const isImpressions = activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id;
          const isFillRate = activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id;

          if (isSummary && isImpressions) {
            typedData = agregateImpressionsForSummaryChart(
              dataBySelectedAggregationType[activeAreaChartType],
              currenciesToDisplay
            );
          }

          return (
            <Box
              id={`${chartItem.id}-area-widget`}
              key={activeAreaChartType}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="bodySmallMedium"
                  sx={{
                    color: colors.textBaseTertiary,
                    // marginBottom: "10px",
                  }}
                >
                  {chartItem.title}
                </Typography>
                {<Box sx={{ scale: "70%", opacity: 0.7 }}>{chartItem.bodyIcon}</Box>}
              </Box>
              <Box sx={{ marginTop: "10px", width: "100%" }}>
                <ResponsiveContainer
                  // width="100%"
                  height={isFullSize ? 300 : itemLastIndex === activeAreaChartType ? 160 : 130}
                >
                  <LineChart
                    width="100%"
                    syncId="anyId"
                    height={isFullSize ? 300 : itemLastIndex === activeAreaChartType ? 160 : 130}
                    isAnimationActive={false}
                    data={typedData}
                    margin={{
                      top: 10,
                      // activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id ? 10 : 10,
                      right: 10,
                      bottom: 20, //dateGroupingMode === CONSTANT.DATE_GROUP_MODES.HOUR ? 0 : 0,
                      left: -20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5ef" strokeWidth={1} />
                    {(isFullSize || itemLastIndex === activeAreaChartType) && (
                      <XAxis
                        dataKey="date"
                        // stroke="#F5F6F7"
                        width={2}
                        axisLine={false}
                        // axisLine={{ stroke: 'red', strokeWidth: 1 }}
                        // tickLine={{ stroke: "none" }}
                        tick={<CustomizedAxisTick h={timeGrouping} dgm={dateGroupingMode} d={typedData} />}
                        // domain={
                        //   timeGrouping === -2
                        //     ? [
                        //         props.data[activeAreaChartType][0].date,
                        //         props.data[activeAreaChartType][props.data[activeAreaChartType].length - 1].date,
                        //       ]
                        //     : [0, "auto"]
                        // }
                      />
                    )}
                    <YAxis
                      tick={apt ? <CustomizedAxisTick /> : null}
                      // tick={false}
                      // stroke="#F5F6F7"
                      tickLine={{ stroke: "none" }}
                      axisLine={false}
                    />
                    <Tooltip
                      // offset={20}
                      cursor={false}
                      position={isFullSize || itemLastIndex === activeAreaChartType ? { y: -50 } : { y: -100 }}
                      content={(props) => (
                        <HoverTooltip
                          props={props}
                          // handleFixedTooltips={(currentFixed) => {
                          //   // console.log(currentFixed);

                          //   setFixedTooltips([...fixedTooltips, currentFixed]);
                          // }}
                        >
                          {isSummary ? (
                            <CustomTooltip
                              apt={apt}
                              currency={chartItem.prefix ?? currency}
                              activeAreaChartType={activeAreaChartType}
                              dgm={dateGroupingMode}
                              chartColors={chartColors}
                              payload={props.payload}
                            />
                          ) : (
                            <CustomTooltipPerPartner
                              apt={apt}
                              currency={chartItem.prefix ?? currency}
                              activeAreaChartType={activeAreaChartType}
                              dgm={dateGroupingMode}
                              chartColors={chartColors}
                              payload={props.payload}
                              isPresentationModeEnabled={isPresentationModeEnabled}
                            />
                          )}
                        </HoverTooltip>
                      )}
                      wrapperStyle={{ outline: "none" }}
                    />
                    {/* {activeAreaChartType !== CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id && (
                <Legend
                  wrapperStyle={{ marginLeft: "55%" }}
                  verticalAlign="top"
                  height={36}
                  iconType={"dot"}
                  iconSize={8}
                  content={
                    <CustomLegend
                      currencies={rawCurrency}
                      onClick={handleLegendClick}
                      currenciesToDisplay={widgetChart.currenciesToDisplay}
                    />
                  }
                />
              )} */}
                    {isSummary ? (
                      isImpressions ? (
                        <Line
                          activeDot={<CustomizedDot />}
                          dot={dataBySelectedAggregationType.length === 1 ? <CustomizedDot /> : false}
                          // type="monotone"
                          dataKey={"CURRENCY"}
                          strokeWidth={2.5}
                          stroke={LINE_COLORS_CURRENCIES_MAP[0]}
                          fill="url(#revenueWithIIQAsStackedVal)"
                          key={"raw_currency_impressions"}
                        />
                      ) : (
                        rawCurrency.map((item, index) => {
                          if (currenciesToDisplay[item]) {
                            return (
                              <Line
                                activeDot={<CustomizedDot />}
                                dot={dataBySelectedAggregationType.length === 1 ? <CustomizedDot /> : false}
                                // type="monotone"
                                dataKey={item}
                                strokeWidth={2.5}
                                //stroke={activeFilters.timeGrouping === -2 ? LINE_COLORS[index] : "#1BB2FF"}
                                stroke={
                                  activeAreaChartType !== CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id ||
                                  props.activeAreaChartType !==
                                    CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id
                                    ? LINE_COLORS_CURRENCIES_MAP[item]
                                    : LINE_COLORS_CURRENCIES_MAP[0]
                                }
                                fill="url(#revenueWithIIQAsStackedVal)"
                                key={"raw_currency_" + index}
                              />
                            );
                          }
                        })
                      )
                    ) : (
                      Object.keys(dataBySelectedAggregationType).map((partner, index) =>
                        isImpressions || isFillRate ? (
                          <Line
                            key={partner}
                            activeDot={<CustomizedDot />}
                            dot={typedData.length === 1 ? <CustomizedDot /> : false}
                            dataKey={partner}
                            strokeWidth={2.5}
                            stroke={chartColors[activeAreaChartType][partner]}
                            fill="url(#revenueWithIIQAsStackedVal)"
                          />
                        ) : (
                          rawCurrency.map((currency) => {
                            const key = `${partner}${CONSTANT.COMBINED_STRING_KEY2}${currency}`;
                            if (currenciesToDisplay[currency]) {
                              return (
                                <Line
                                  key={key}
                                  activeDot={<CustomizedDot />}
                                  dot={typedData.length === 1 ? <CustomizedDot /> : false}
                                  dataKey={key}
                                  strokeWidth={2.5}
                                  stroke={chartColors[activeAreaChartType][key]}
                                  fill="url(#revenueWithIIQAsStackedVal)"
                                />
                              );
                            }
                          })
                        )
                      )
                    )}
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default AreaChartWidget;
