import React, { ReactNode } from "react";
import { Box, Button, Divider, Step, Stepper, Typography } from "@mui/material";
import { colors } from "../../theme";

interface Step {
  id: number;
  name: string;
}

interface CustomStepperProps {
  steps: Step[];
  activeStepIndex: number;
  nextDisabled: boolean;
  handleStepAction: () => void;
  handleStep: (stepIndex: number) => () => void;
  finalStepActionButtonTitle: string;
  children: ReactNode;
  deleteButton?: ReactNode;
}

const CustomStepper: React.FC<CustomStepperProps> = ({
  steps,
  activeStepIndex,
  nextDisabled,
  handleStepAction,
  handleStep,
  finalStepActionButtonTitle,
  children,
  deleteButton,
}) => {
  const isLastStep = activeStepIndex === steps.length - 1;

  return (
    <Box
      sx={{
        width: "100%",

        padding: "0 24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "16px",
        }}
      >
        <Stepper
          nonLinear
          alternativeLabel
          activeStep={activeStepIndex}
          connector={null}
          sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
        >
          {steps.map((step, index) => (
            <Step
              disabled={nextDisabled}
              key={step.id}
              sx={{
                borderBottom:
                  step.id === steps[activeStepIndex].id
                    ? `2px solid ${colors.text01}`
                    : `2px solid ${colors.textBaseTertiary}`,
                padding: "8px 0 0 0",
              }}
              onClick={handleStep(index)}
            >
              <Button variant="clearAll" id={`${step.name.toLowerCase().replaceAll(" ", "-")}-tab-btn`}>
                <Typography
                  variant="bodyLargeRegular"
                  sx={{
                    fontWeight: step.id === steps[activeStepIndex].id ? 600 : 500,
                    color:
                      step.id === steps[activeStepIndex].id ? `${colors.text01}` : `${colors.textBaseTertiary}`,
                  }}
                >
                  {step.name}
                </Typography>
              </Button>
            </Step>
          ))}
        </Stepper>

        <Box>{children}</Box>
      </Box>
      <Box sx={{}}>
        <Divider variant="fullWidth" />
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "16px" }}>
          <Box>{deleteButton}</Box>

          <Button
            id={isLastStep ? "save-btn" : "next-btn"}
            disabled={nextDisabled}
            variant="apply"
            size="customMedium"
            onClick={handleStepAction}
            sx={{ color: nextDisabled ? colors.textDisabled : colors.mainWhite }}
          >
            {isLastStep ? finalStepActionButtonTitle : "Next step"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomStepper;
