import React from "react";

export const OverviewIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5 11C4.45 11 3.97917 10.8042 3.5875 10.4125C3.19583 10.0208 3 9.55 3 9V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9C9.55 3 10.0208 3.19583 10.4125 3.5875C10.8042 3.97917 11 4.45 11 5V9C11 9.55 10.8042 10.0208 10.4125 10.4125C10.0208 10.8042 9.55 11 9 11H5ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V15C3 14.45 3.19583 13.9792 3.5875 13.5875C3.97917 13.1958 4.45 13 5 13H9C9.55 13 10.0208 13.1958 10.4125 13.5875C10.8042 13.9792 11 14.45 11 15V19C11 19.55 10.8042 20.0208 10.4125 20.4125C10.0208 20.8042 9.55 21 9 21H5ZM15 11C14.45 11 13.9792 10.8042 13.5875 10.4125C13.1958 10.0208 13 9.55 13 9V5C13 4.45 13.1958 3.97917 13.5875 3.5875C13.9792 3.19583 14.45 3 15 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V9C21 9.55 20.8042 10.0208 20.4125 10.4125C20.0208 10.8042 19.55 11 19 11H15ZM15 21C14.45 21 13.9792 20.8042 13.5875 20.4125C13.1958 20.0208 13 19.55 13 19V15C13 14.45 13.1958 13.9792 13.5875 13.5875C13.9792 13.1958 14.45 13 15 13H19C19.55 13 20.0208 13.1958 20.4125 13.5875C20.8042 13.9792 21 14.45 21 15V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H15ZM5 9H9V5H5V9ZM15 9H19V5H15V9ZM15 19H19V15H15V19ZM5 19H9V15H5V19Z"
        fill="#B8C1D1"
      />
    </svg>
  );
};

export const OverviewIconHeader = (props) => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.26056 0.197266H10.2606C13.2061 0.197266 15.5939 2.58508 15.5939 5.53058V9.53058C15.5939 12.4761 13.2061 14.8639 10.2606 14.8639H6.26056C3.31506 14.864 0.927246 12.4761 0.927246 9.53058V5.53058C0.927246 2.58508 3.31506 0.197266 6.26056 0.197266Z"
        fill="#001738"
      />
      <path
        d="M23.5941 0.197266H27.5941C30.5396 0.197266 32.9274 2.58508 32.9274 5.53058V9.53058C32.9274 12.4761 30.5396 14.8639 27.5941 14.8639H23.5941C20.6486 14.8639 18.2607 12.4761 18.2607 9.53058V5.53058C18.2607 2.58508 20.6485 0.197266 23.5941 0.197266Z"
        fill="#001738"
      />
      <path
        d="M6.26056 17.5306H10.2606C13.2061 17.5306 15.5939 19.9185 15.5939 22.864V26.8639C15.5939 29.8095 13.2061 32.1973 10.2606 32.1973H6.26056C3.31506 32.1973 0.927246 29.8095 0.927246 26.864V22.864C0.927246 19.9185 3.31506 17.5306 6.26056 17.5306Z"
        fill="#001738"
      />
      <path
        d="M23.5941 17.5306H27.5941C30.5396 17.5306 32.9274 19.9185 32.9274 22.864V26.864C32.9274 29.8095 30.5396 32.1973 27.5941 32.1973H23.5941C20.6486 32.1973 18.2607 29.8095 18.2607 26.864V22.864C18.2607 19.9185 20.6485 17.5306 23.5941 17.5306Z"
        fill="#001738"
      />
    </svg>
  );
};
