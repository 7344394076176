import React, { ErrorInfo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import auth from "../../auth/auth-helper";
import { CONSTANT } from "../../constants/constants";
import { ILogData, postLog } from "./backendLogApi";

// Define the types for the props and the state
interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to an error reporting service here
    console.error("Error caught by Error Boundary:", error, errorInfo);
    this.logErrorToService(error, errorInfo);
  }

  logErrorToService(error: Error, errorInfo: ErrorInfo) {
    const jwt = auth.isAuthenticated();
    const logData: ILogData = {
      level: "error",
      message: error.toString() + " || " + errorInfo.componentStack,
    };
    postLog(logData, jwt.data.accessToken);
  }

  handleGoHome = () => {
    window.location.href = "/";
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Custom MUI styled fallback UI
      return (
        <Box sx={{ mt: 4, mx: 2, textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            Something went wrong.
          </Typography>
          <Button variant="contained" color="primary" onClick={this.handleGoHome} sx={{ m: 1 }}>
            Go Home
          </Button>
          <Button variant="outlined" color="secondary" onClick={this.handleReload} sx={{ m: 1 }}>
            Reload
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
