import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import CloseIcon from "@mui/icons-material/Close";
import { columnsModel } from "./columnsModel";
import SegmentControl from "../../components/SegmentControl/SegmentControl";
import BidderConfigurationForm from "./BidderConfigurationForm";
import { colors } from "../../theme";
import { id } from "date-fns/locale";
import {
  useDeletePartnersConfigurationById,
  useGetAllConfiguration,
  useUpdatePartnersConfigurationById,
  useUpdatePartnersResolutionById,
} from "./configurationsApi";
import { IBidderConfig } from "./interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ConfigurationStatusMenu from "./ConfigurationStatusMenu";
import StyledDataGrid from "../../components/StyledDataGrid/StyledDataGrid";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";

export type IBidderConfigKey = keyof IBidderConfig;

const ConfigurationPage: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [configToEdit, setConfigToEdit] = useState<IBidderConfig | null>(null);
  const [configByPartner, setConfigByPartner] = useState<IBidderConfig[] | undefined>(undefined);
  const { data: configuration } = useGetAllConfiguration();
  const [deletePartnersConfigurationById] = useDeletePartnersConfigurationById();
  const [updatePartnersConfigurationById, { isLoading }] = useUpdatePartnersConfigurationById();
  const [updatePartnersResolutionById] = useUpdatePartnersResolutionById();
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);

  const { selectedPartnerId } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.configurationParameters
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const [idResolutionEnabled, setIdResolutionEnabled] = useState<boolean>(false);
  const [configId, setConfigId] = useState<number | null>(null);
  const [statusPropName, setStatusPropName] = useState<IBidderConfigKey | null>(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
  };

  const handleOpenDialog = () => {
    setConfigToEdit(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleStatusClick = (
    configId: any,
    selectedStatus: number,
    statusKey: IBidderConfigKey,
    anchorEl: HTMLElement
  ) => {
    setSelectedStatus(selectedStatus);
    setConfigId(configId);
    setStatusPropName(statusKey);
    setAnchorEl(anchorEl);
  };
  const handleOpenDeleteConfirmationDialog = (config: IBidderConfig | undefined) => {
    if (config) {
      setConfigToEdit(config);
      setOpenDeleteConfirmationDialog(true);
    }
  };
  const handleCloseDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false);
  };

  const handleOnidResolutionEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (selectedPartnerId) {
        updatePartnersResolutionById({
          pdid: selectedPartnerId,
          data: { isServiceActive: event.target.checked ? 1 : 0 },
        });
        setIdResolutionEnabled(event.target.checked);
      }
    } catch (e) {
      console.log("Error in handleOnidResolutionEnabledChange: ", e);
    }
  };

  const handleNewStatusClick = async (newStatus: number) => {
    if (configId && statusPropName)
      try {
        await updatePartnersConfigurationById({
          id: configId,
          data: { [statusPropName]: newStatus },
        }).unwrap();
      } catch (error) {
        console.error("Failed to update status:", error);
      }
    setAnchorEl(null);
  };

  useEffect(() => {
    const enabledConfigs = configByPartner?.find((c) => c.isServiceActive === 1);
    setIdResolutionEnabled(!!enabledConfigs);
  }, [configByPartner]);

  useEffect(() => {
    setConfigByPartner(configuration?.filter((c) => c.partnerDummyId === selectedPartnerId));
  }, [configuration, selectedPartnerId]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <Box sx={{ display: "flex" }}>
        <SegmentControl
          options={[{ id: 1, name: "Bidders configuration" }]}
          value={{ id: 1, name: "Bidders configuration" }}
          onChange={() => {}}
        />
      </Box>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="body2XSmall" color="textSecondary" gutterBottom>
            Here you define the bidders that you work with along with all connection configuration
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" gap={"12px"}>
          <Typography variant="body1Small400">ID Resolution</Typography>
          <Switch
            disabled={!selectedPartnerId}
            checked={idResolutionEnabled || false}
            onChange={handleOnidResolutionEnabledChange}
            size="medium"
          />
          <Button disabled={!selectedPartnerId} onClick={handleOpenDialog} variant="apply">
            Add Bidder
          </Button>
        </Box>
      </Box>

      <Box style={{ height: 400, width: "100%" }}>
        <StyledDataGrid
          rows={configByPartner || []}
          columns={columnsModel({
            onEdit: (id) => {
              setConfigToEdit(configuration?.find((c) => c.id === id) || null);
              setOpenDialog(true);
              console.log("Edit clicked for ID:", id);
            },
            onDelete: async (id) => {
              handleOpenDeleteConfirmationDialog(configByPartner?.filter((c) => c.id === id)[0]);
            },
            onServiceActiveStatusClick: handleStatusClick,
            onBidderenabledStatusClick: handleStatusClick,
          })}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 50, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        sx={{
          background: `${colors.text01}99`,
        }}
        PaperProps={{
          sx: {
            display: "flex",
            width: "600px",
            borderRadius: "24px",
            gap: "16px",
            height: "756px",
          },
        }}
      >
        <DialogTitle
          id={`${99}-title`}
          sx={{
            textAlign: "start",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "24px 24px 0 24px",
          }}
        >
          <Typography variant="titleSmall">Add a New Bidder</Typography>
        </DialogTitle>
        <IconButton
          id={`${id}-close-btn`}
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            color: `${colors.foreground}`,
            padding: "6px",
          }}
        >
          <CloseIcon
            aria-hidden="false"
            sx={{
              width: "24px",
              height: "24px",
            }}
          />
        </IconButton>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: `${colors.foreground01}`,
            paddingBottom: "16px",
          }}
        >
          <BidderConfigurationForm
            onSubmit={handleCloseDialog}
            partnerDummyId={selectedPartnerId}
            initialFormData={configToEdit}
          />
        </DialogContent>
      </Dialog>
      <ConfigurationStatusMenu
        currentStatus={selectedStatus}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onStatusClick={handleNewStatusClick}
      />
      <ConfirmationDialog
        isOpen={openDeleteConfirmationDialog}
        title={`Do you want to delete ${configToEdit?.bidderName} from the bidders list?`}
        confirmButtonTitle="Delete bidder"
        declineButtonTitle="Cancel"
        onConfirm={() => {
          if (configToEdit) {
            deletePartnersConfigurationById(configToEdit.id.toString())
              .unwrap()
              .then(() => {
                alert(`Configuration with id ${configToEdit.id} deleted`);
                handleCloseDeleteConfirmationDialog();
              });
          }
        }}
        onDecline={handleCloseDeleteConfirmationDialog}
      />
    </Box>
  );
};

export default ConfigurationPage;
