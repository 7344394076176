import {
  convertRawData,
  getValuesFromRawDataForWidgets,
  convertToValues,
  prepareLineChartsForWidgetBoxes,
  getReportValues,
  prepareReportDates,
  getReportPctModes,
  filterStatsDataByUnitIds,
} from "../utils";
import { getAvaliableUnit } from "../reportSlice";
import { CONSTANT } from "../../constants/constants";

// let arrOfKeys = ["submitted_date", "client_type", "site_id", "biddercode"];

export const getReportReducer = (state, action) => {
  if (!action.payload.result.data.length) {
    state.recs = [];
    state.active_chart = action.payload.activeChart;
    state.filterBase = action.payload.activeChart === 0 ? "summary" : action.payload.filterBase;
    // state.active_range_date = action.payload.activeRangeDate;
    state.units = [];
    state.unit = [];
    state.selectedPct = null;
    state.rawPct = [];

    state.processing = false;
    state.widgetDataCurrentPerodProcessing = false;

    state.currentPeriodSummaryValues = [];
    state.widgetChartCurrentData = {};
    state.comparisonResult = {};
    state.summaryCompleteData = {
      previousPeriodSummaryValues: null,
      currentPeriodSummaryValues: null,
    };
    return;
  }
  state.isMissingSettings = [];
  const requestChartBase = action.payload.filterBase;
  const currentFilterBase = action.payload.currentFilterBase;

  let { pctModes, currencyModes, uniqPairs, maxCurrencyPctCombination } = getReportPctModes(
    action.payload.result.data
  );
  //console.log('maxCurrencyPctCombination: ',maxCurrencyPctCombination)
  let { stats, activeRangeDate, base, dateGroupingMode } = getReportValues(action);

  const parametersConstancy = action.payload.parametersConstancy;

  //*** Solution for PCT for Strict Mode
  // if (!parametersConstancy.selectedPct) {
  //   setParametersConstancyWrapper("selectedPct", "", "", +uniqPairs[0].pct);
  //   setParametersConstancyWrapper("selectedCurrency", "", "", uniqPairs[0].currency);
  // }

  // if (!state.selectedPct) {
  //   state.selectedPct = currentPct;
  //   state.selectedCurrency = currentCurrency;
  // }

  // if (action.payload.settingsStrictMode) {
  //   if (!pctModes.includes(+state.selectedPct)) {
  //     state.selectedPct = currentPct;
  //     state.selectedCurrency = currentCurrency;
  //     //*** notification that this pair doesnt exist in the new data
  //     pctModes.push(currentPct)
  //     uniqPairs.push({
  //       currency: currentCurrency,
  //       pct: currentPct
  //     })
  //   }
  // } else {
  //   if (!pctModes.includes(+state.selectedPct)) {
  //     state.selectedPct = +uniqPairs[0].pct;
  //     state.selectedCurrency = uniqPairs[0].currency;
  //   }
  // }
  //*** Solution for PCT for Strict Mode

  //*** Solution without Strict Mode for PCT

  if (!uniqPairs.some((pair) => pair.currency === state.selectedCurrency && pair.pct === state.selectedPct)) {
    state.selectedPct = +maxCurrencyPctCombination.pct;
    state.selectedCurrency = maxCurrencyPctCombination.currency;
  }
  //*** Solution without Strict Mode for PCT

  if (pctModes.length > 1 || currencyModes.length > 1) {
    stats = stats.filter((i) => i.pct === state.selectedPct && i.currency === state.selectedCurrency);
  }

  let chartData = [];
  let categories = [];

  //* get proper dates
  let { reportDateStart, chartLastDate } = prepareReportDates(activeRangeDate, action, state);

  //* Widget chart
  let widgetChartDataCurPeriod = prepareLineChartsForWidgetBoxes(
    stats,
    reportDateStart.startDate,
    chartLastDate,
    state.selectedPct,
    dateGroupingMode
  );

  //* Calculate and Convert raw data to Widget's appropiate object , calc levelOfAllowableValues - to ignore very low or minor values (based on SUM(total_rows1))
  let agregatedValues = getValuesFromRawDataForWidgets(stats);
  let levelOfAllowableValues = (agregatedValues.total_rows1 * state.clutterRemovalPercentage.crpWithIIQ) / 100;

  if (action.payload.skipSetBaseAsActive === false && "filterBase" in action.payload) {
    state.filterBase = action.payload.filterBase;
  }

  //* Convert raw data to Chart's appropiate object
  if (stats.length) {
    chartData = convertRawData(
      stats,
      base,
      reportDateStart.startDate,
      chartLastDate,
      action.payload.activeChart,
      [base],
      levelOfAllowableValues,
      dateGroupingMode
    );
    categories = getAvaliableUnit(chartData.lift_type[0], chartData.keyValuePairs);
  }

  state.recs = chartData;
  state.rawPct = pctModes;
  state.uniqPairs = uniqPairs;
  // state.statsRaw = action.payload.result.data;//save in store raw data
  state.active_chart = action.payload.activeChart;
  // state.active_range_date = activeRangeDate;
  state.units = categories;

  let preselectedValues = [];
  if (state.clutterRemovalPercentage.deselectionPercentage) {
    //* for chart 1 - revenue - total_cpm1
    if (action.payload.activeChart === 1 || action.payload.activeChart === 0) {
      let levelOfPreselctedValues =
        (agregatedValues.total_cpm1 * state.clutterRemovalPercentage.deselectionPercentage) / 100;
      let names = chartData.ab_group_type?.map((i) => i.gr_a_cash > levelOfPreselctedValues && i.name);
      preselectedValues = categories.filter((i) => names.includes(i.partnerInstaceId));
    }
    if (action.payload.activeChart === 2) {
      let levelOfPreselctedValues =
        (agregatedValues.total_rows1 * state.clutterRemovalPercentage.deselectionPercentage) / 100;
      let names = chartData.ab_group_type?.map((i) => i.gr_a_imp > levelOfPreselctedValues && i.name);
      preselectedValues = categories.filter((i) => names.includes(i.partnerInstaceId));
    }
  }

  //* set datas for widgets after api response ,and original "full" data to  summaryCompleteData. NO filtering by units from LS here
  // state.currentSummaryRawData = agregatedValues; //seems this is not in usage - commented for a while
  state.currentPeriodSummaryValues = convertToValues(agregatedValues, state.selectedPct);

  // FIXME - check why with site_id state.currentPeriodSummaryValues is changing
  state.summaryCompleteData.currentPeriodSummaryValues = state.currentPeriodSummaryValues; // this summaryCompleteData should not be changed - it keeps original "full" data
  state.summaryCompleteData.widgetChartDataCurPeriod = widgetChartDataCurPeriod; // this widgetChartDataCurPeriod should not be changed - it keeps original "full" data
  //* widget chart  - all datas (as it in raw)
  state.widgetChartCurrentData = widgetChartDataCurPeriod;

  //* refresh previous units and set new one, when switching between chart types (rev lift/ cpm lift/ fill rate lift)
  //* in case when base or time range changed or units arr is empty
  //* and recalculate data for 'widget boxes'

  //(0*) closing of this if before  -> state.processing = false;
  // if ("skipRefreshUints" in action.payload == false || action.payload?.skipRefreshUints == true || state.unit.length == 0) {

  const reportFilters = action.payload.reportFilters;
  const activeFilters = Object.fromEntries(
    Object.entries(reportFilters).map(([key, values]) => {
      const updatedValues = values.filter((unit) => unit.isSelected);
      return [key, updatedValues];
    })
  );
  let storedUnitsFromActiveFilters;
  const createActiveFilterItems = (activeFilterBase) => {
    if (
      activeFilters[activeFilterBase].length > 0 &&
      activeFilters[activeFilterBase].length === reportFilters[activeFilterBase].length &&
      activeFilters[activeFilterBase].length >= categories.length
    ) {
      return [
        {
          partnerInstaceId: CONSTANT.REPORTS.SELECTED_ALL_OBJECT.name,
          clientItemName: CONSTANT.REPORTS.SELECTED_ALL_OBJECT.name,
          unitId: "" + CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id,
          color: `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`,
        },
      ];
    } else {
      return activeFilters[activeFilterBase].map((item) => ({
        partnerInstaceId: item.name,
        clientItemName: item.name,
        unitId: "" + item.id,
        color: `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`,
      }));
    }
  };

  const filterBaseConfig = {
    [CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE]: {
      activeFilterBase: CONSTANT.REPORTS.FILTERS_KEYS.BROWSERS.base,
    },
    [CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE]: {
      activeFilterBase: CONSTANT.REPORTS.FILTERS_KEYS.BIDDERS.base,
    },
    [CONSTANT.OVERVIEW.CHART_BASES.SITE_ID]: {
      activeFilterBase: CONSTANT.REPORTS.FILTERS_KEYS.SITES.base,
    },
  };
  if (filterBaseConfig[requestChartBase]) {
    const { activeFilterBase } = filterBaseConfig[requestChartBase];
    storedUnitsFromActiveFilters = createActiveFilterItems(activeFilterBase);
  }
  let initUnit = preselectedValues.length > 0 ? preselectedValues : categories;
  let storedUnits =
    currentFilterBase === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY
      ? []
      : storedUnitsFromActiveFilters?.length > 0
      ? storedUnitsFromActiveFilters
      : action.payload.temporaryStoredUnits ||
        parametersConstancy?.overviewParameters?.chartBaseUnits[requestChartBase]?.unit ||
        [];

  const categoryIds = categories.map((category) => category.partnerInstaceId);
  let updatedUnit =
    storedUnits[0]?.unitId === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id
      ? categories
      : storedUnits.filter((unit) => categoryIds.includes(unit.partnerInstaceId));

  if (action.payload.settingsStrictMode) {
    state.isMissingSettings =
      storedUnits[0]?.unitId === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id
        ? []
        : storedUnits.filter((unit) => !categoryIds.includes(unit.partnerInstaceId));
    if (storedUnits.length === 0) {
      state.unit = initUnit;
    } else {
      state.unit = updatedUnit;
    }
  } else {
    updatedUnit = updatedUnit.length ? updatedUnit : initUnit;
    state.unit = updatedUnit;
  }

  let statsFiltered = stats;
  if (chartData.excludedValues?.length > 0) {
    //remove from rawData excluded values
    let ids = chartData.excludedValues.map((i) => i.name);
    statsFiltered = statsFiltered.filter((i) => {
      return !ids.includes(i[base]);
    });
    agregatedValues = getValuesFromRawDataForWidgets(statsFiltered);
  }

  // *****************filter data for widgetcharts line chart (1*) and numbers in widget boxes (2*)**************

  statsFiltered = filterStatsDataByUnitIds(statsFiltered, requestChartBase, state.unit);
  agregatedValues = getValuesFromRawDataForWidgets(statsFiltered);
  // state.currentSummaryRawData = agregatedValues; //seems this is not in usage - commented for a while
  state.currentPeriodSummaryValues = convertToValues(agregatedValues, state.selectedPct);
  // state.summaryCompleteData.currentPeriodSummaryValues = state.currentPeriodSummaryValues; //should not be updated - here original stuff

  // state.widgetChartCurrentData = widgetChartDataCurPeriod;
  //* should be updated according to units
  //Widget chart (1*) - recalculated with respect to units
  widgetChartDataCurPeriod = prepareLineChartsForWidgetBoxes(
    statsFiltered,
    reportDateStart.startDate,
    chartLastDate,
    state.selectedPct,
    dateGroupingMode
  );

  state.widgetChartCurrentData = widgetChartDataCurPeriod;

  //} -> closing of if statement (0*)

  state.processing = false;
  state.widgetDataCurrentPerodProcessing = false;
};
