import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Loader from "../../components/Loader";
import { formFieldsValidator, validatorFunctions } from "../helpers/board-helpers";
import { boardWrapperStl, stl, titleStl, titleStlAsterics, inputSx } from "./boardStl";
import { buttonSx } from "../../core/performanceReports/advancedReport/styles";
import { useCreateAccountMutation } from "../accountsApi";
import FileUploadComponent from "./FileUploadComponent";
import { CONSTANT } from "../../constants/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserMetadataByToken } from "../../auth/auth-api";
const INPT_LENGTH = 50;

export default function CreateAccountBoardHandler(props) {
  const [showLoader, setShowLoader] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const [createAccount, { isLoading }] = useCreateAccountMutation(); // Hook for mutation

  const [formValues, setFormValues] = useState({
    company: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, enter company name (will be used in this account name)",
      validationFunction: [(val) => +val.trim().length < INPT_LENGTH, (val) => !!val.trim().length],
    },
    partnerCompanyName: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "Please, enter partner/company name",
      validationFunction: [
        (val) => {
          // if (val.trim().length == 0) return true;
          return true;
        },
      ],
    },
    pid: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "Please, assign partner id (PID) - optional",
      validationFunction: [
        (val) => {
          if (val.trim().length == 0) return true;
          let patt = new RegExp(/^\d+$/); // ('+' is optional)
          return patt.test(val);
        },
      ],
    },
    pdid: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "Please, enter designated partner id (PDID) - optional",
      validationFunction: [
        (val) => {
          if (val.trim().length == 0) return true;
          let patt = new RegExp(/^\d+$/); // ('+' is optional)
          return patt.test(val);
        },
      ],
    },
    firstName: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, enter user's name (as account administrator) (" + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.firstName,
    },
    lastName: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, enter user's last name (as account administrator)(" + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.lastName,
    },
    email: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, add an email addres in correct format",
      validationFunction: validatorFunctions.email,
    },
    phone: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, add phone num. (+XXXXXXXXXXXX ('+' is optional))",
      validationFunction: validatorFunctions.phone,
    },
    position: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Text too long",
      validationFunction: validatorFunctions.position,
    },
    photo: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "---",
      validationFunction: [(v) => 1],
    },
  });

  const hanldleRemovePhoto = () => {
    setFormValues({
      ...formValues,
      ["photo"]: { ...formValues["photo"], value: "", error: false },
    });
    setSelectedFileName("");
  };

  const handleChange = (e) => {
    const val = e.target.name === "photo" ? e.target.files[0] : e.target.value;

    if (e.target.name === "photo") {
      setFormValues({
        ...formValues,
        ["photo"]: { ...formValues["photo"], value: "", error: "" },
      });

      if (!val) return;

      const fileSizeBytes = val?.size;
      if (!CONSTANT.ALLOWED_FILE_IMAGE_TYPES.includes(val?.type)) {
        setFormValues({
          ...formValues,
          ["photo"]: {
            ...formValues["photo"],
            value: "",
            error: "File is not added, only JPEG, JPG, PNG, SVG images are allowed.",
          },
        });
        return;
      }

      if (fileSizeBytes > CONSTANT.MAX_FILE_SIZE) {
        const bytes = CONSTANT.MAX_FILE_SIZE;
        const megabytes = (bytes / (1024 * 1024)).toFixed(2) + "MB";
        let str = "File is not added, size is greater than maximum limit, max: " + megabytes;
        setFormValues({
          ...formValues,
          ["photo"]: { ...formValues["photo"], value: "", error: str },
        });
        return;
      }

      if (!!val) setSelectedFileName(e.target.files[0].name);
    }

    setFormValues({
      ...formValues,
      [e.target.name]: { ...formValues[e.target.name], value: val, error: false },
    });
  };

  const handleRetrieve = async () => {
    let { newFormValues, errors } = formFieldsValidator(formValues);
    if (!!errors.length) {
      setFormValues(newFormValues);
      return;
    }

    setShowLoader(true);

    let data = new FormData();
    data.append("company", formValues.company.value);
    data.append("photo", formValues.photo.value);
    data.append(
      "userData",
      JSON.stringify([
        {
          firstName: formValues.firstName.value,
          lastName: formValues.lastName.value,
          phone: formValues.phone.value,
          email: formValues.email.value,
          position: formValues.position.value,
        },
      ])
    );
    try {
      const result = await createAccount(data);
      if (result.error) {
        console.log(result.error);
        alert("Account is not created: " + result.error);
      } else {
        // Account created successfully
        const token = await getAccessTokenSilently();
        await getUserMetadataByToken(token, dispatch, false, null, true);
        setShowLoader(false);
        props.onClose();
      }
    } catch (error) {
      console.error("Error creating account:", error);
      setShowLoader(false);
      alert("An error occurred: " + error.message);
    }
  };

  let isDisabled =
    !Boolean(formValues.company.value) ||
    !Boolean(formValues.firstName.value) ||
    !Boolean(formValues.lastName.value) ||
    !Boolean(formValues.email.value);

  if (showLoader) return <Loader sx={{ height: "80vh" }} />;

  return (
    <div id="create-account-popup">
      <div style={boardWrapperStl}>
        <Box id="account-details-subtitle">Account details</Box>

        {/* Company name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Company name
          </Box>
          <TextField
            id="partner-input"
            sx={inputSx}
            onChange={handleChange}
            error={formValues.company.error}
            helperText={formValues.company.error && formValues.company.errorMessage}
            value={formValues.company.value}
            placeholder="Company name"
            name="company"
            autoFocus
          />
        </Box>

        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
            }}
          >
            Company logo
          </Box>
          <FileUploadComponent
            handleFileChange={handleChange}
            handleButtonClick={handleButtonClick}
            handleRemovePhoto={hanldleRemovePhoto}
            selectedFileName={selectedFileName}
            fileUploadError={formValues.photo.error}
            ref={fileInputRef}
          />
        </Box>

        <Box id="administrator-subtitle">Account administrator details</Box>

        {/* First name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            First name
          </Box>
          <TextField
            id="first-name-input"
            sx={inputSx}
            onChange={handleChange}
            error={formValues.firstName.error}
            helperText={formValues.firstName.error && formValues.firstName.errorMessage}
            value={formValues.firstName.value}
            placeholder="First name"
            name="firstName"
          />
        </Box>

        {/* Last name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Last name
          </Box>
          <TextField
            id="last-name-input"
            sx={inputSx}
            onChange={handleChange}
            error={formValues.lastName.error}
            helperText={formValues.lastName.error && formValues.lastName.errorMessage}
            value={formValues.lastName.value}
            name="lastName"
            placeholder="Last name"
          />
        </Box>

        {/* Phone number */}
        <Box sx={stl}>
          <Box sx={titleStl}>Phone number</Box>
          <TextField
            id="phone-input"
            sx={inputSx}
            placeholder="+XXXXXXXXXXXX ('+' is optional)"
            name="phone"
            value={formValues.phone.value}
            onChange={handleChange}
            error={formValues.phone.error}
            helperText={formValues.phone.error && formValues.phone.errorMessage}
          />
        </Box>

        {/* email */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Email
          </Box>
          <TextField
            id="email-input"
            sx={inputSx}
            placeholder="Enter user's email"
            name="email"
            required
            value={formValues.email.value}
            onChange={handleChange}
            error={formValues.email.error}
            helperText={formValues.email.error && formValues.email.errorMessage}
          />
        </Box>

        {/* position */}
        <Box sx={stl}>
          <Box sx={titleStl}>Position</Box>
          <TextField
            id="position-input"
            sx={inputSx}
            placeholder="Enter users' position"
            name="position"
            value={formValues.position.value}
            onChange={handleChange}
            error={formValues.position.error}
            helperText={formValues.position.error && formValues.position.errorMessage}
          />
        </Box>
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <Button id="save-btn" sx={buttonSx} onClick={handleRetrieve} disabled={isDisabled}>
          Add new account
        </Button>
      </div>
    </div>
  );
}
