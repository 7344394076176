import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import ReportChartWrapper from "../advancedReport/reportCharts/ReportChartWrapper";
import { VRPieChart } from "./VRCharts/VRPieChart";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ChartData, SummaryCategoryResult } from "../types";
import { CONSTANT } from "../../../constants/constants";
import { ISelectableItem } from "../../../components/SelectItems/interfaces";
import { SelectChangeEvent } from "@mui/material";
import VRBarChart from "./VRCharts/VRBarChart";
import VRLineChart from "./VRCharts/VRLineChart";
import { summarizeCategoryDataByDate } from "./vrUtils";
import { colors, graphColors } from "../../../theme";
import { generateDateRange } from "../advancedReport/utils";

export const defaultChartSelectionTypes = {
  totalRecords: {
    id: 0,
    name: "Total records",
    isSelected: true,
    selectionName: "totalRecords",
    color: graphColors.blueGraph100,
  },
  totalDataSent: {
    id: 1,
    name: "Data sent",
    isSelected: true,
    selectionName: "dataSendTotalRecords",
    color: graphColors.dppGraph100,
  },
};
export const actionTerminationChartSelectionTypes = {
  totalRecords: { id: 0, name: "Total", isSelected: true, selectionName: "totalRecords" },
  totalOnlineRecords: { id: 1, name: "Online", isSelected: false, selectionName: "totalOnlineRecords" },
};

export const defaultChartSelection: ISelectableItem[] = Object.values(defaultChartSelectionTypes);
export const pieChartSelection: ISelectableItem[] = Object.values(actionTerminationChartSelectionTypes);

export const chartTypes = {
  termination: CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base,
  platforms: CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base,
  default: "default",
};

export interface VRWidgetsPanelProps {
  chartType: string;
}

const VRWidgetsPanel: React.FC<VRWidgetsPanelProps> = ({ chartType }) => {
  const { vrDataSummary, vrData }: { vrDataSummary: any; vrData: any } = useSelector(
    (state: RootState) => state.advancedReport
  );
  const { dateGroupingMode, dateRange, customDateRange } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const firstDateOfAllTime = vrData[vrData.length - 1]?.dateCreated;
  const dateRangeTemplate = generateDateRange(
    dateRange.value,
    customDateRange?.customDateStart,
    customDateRange?.customDateEnd,
    dateGroupingMode,
    null,
    firstDateOfAllTime
  ).map((dateString) => {
    if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
      const [year, month] = dateString.split("-");
      return `${year}-${month.padStart(2, "0")}`;
      // const date = new Date(Date.UTC(year, month, 1));
      // return date.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
    } else {
      return dateString;
    }
  });
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [summaryCategoryByDate, setSummaryCategoryByDate] = useState<SummaryCategoryResult[]>([]);
  const [coverageData, setCoverageData] = useState<ChartData[]>([]);
  const [selectedChartItem, setSelectedChartItem] = useState<string>(
    actionTerminationChartSelectionTypes.totalRecords.selectionName
  );
  const [defaultSelectedChartItems, setDefaultSelectedChartItems] =
    useState<ISelectableItem[]>(defaultChartSelection);
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let data: ChartData[] = [];
    if (
      chartType === chartTypes.termination &&
      Object.values(vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base] ?? [])
        ?.length > 0
    ) {
      data =
        Object.values(vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base])?.map(
          (item: any) => {
            return { name: item.categoryItemName, value: item.summary.totalRecords };
          }
        ) ?? [];
      // Assuming your data is stored in a variable called 'data'
      const summarizedData: SummaryCategoryResult =
        summarizeCategoryDataByDate(vrData, dateRangeTemplate, dateGroupingMode) ?? {};
      // Convert to the desired array format
      const result: SummaryCategoryResult[] = Object.entries(summarizedData).map(([date, summary]) => ({
        [date]: summary,
      }));

      setSummaryCategoryByDate(result);
    } else if (
      chartType === chartTypes.platforms &&
      Object.values(vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base] ?? [])?.length > 0
    ) {
      data =
        Object.values(vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base])?.map(
          (item: any) => {
            return { name: item.categoryItemName, value: item.summary.totalTransactionsWith3rdParty };
          }
        ) ?? [];
      const coverageData =
        Object.values(vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base])?.map(
          (item: any) => {
            return { name: item.categoryItemName, value: item.summary.transactionsCoverage };
          }
        ) ?? [];
      setCoverageData(coverageData);
    } else if (chartType === chartTypes.default) {
      const summarizedData: SummaryCategoryResult =
        summarizeCategoryDataByDate(vrData, dateRangeTemplate, dateGroupingMode) ?? {};
      // Convert to the desired array format
      const result: SummaryCategoryResult[] = Object.entries(summarizedData).map(([date, summary]) => ({
        [date]: summary,
      }));

      setSummaryCategoryByDate(result);
      if (result.length > 0) {
        data = [{ name: "default", value: 0 }];
      } else {
        data = [{ name: "emptyDefault", value: 0 }];
      }
    }
    setChartData(data);
  }, []);

  const handleChartDataSelection = (event: SelectChangeEvent) => {
    setSelectedChartItem(event.target.value);
    const selectedChartItem = event.target.value;
    const data = Object.values(
      vrDataSummary?.categorySummary[CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base]
    ).map((item: any) => {
      return { name: item.categoryItemName, value: item.summary[`${selectedChartItem}`] };
    });
    setChartData(data);
  };

  return (
    <Box sx={{ position: "relative", marginBottom: "1rem" }}>
      <Box
        id="hide-widgets-btn"
        sx={{ position: "relative", marginLeft: "23px", marginBottom: "5px", cursor: "pointer" }}
        onClick={handleClick}
      >
        <Box style={{ position: "absolute", top: "-15px", left: "-30px" }}>
          {open ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </Box>
      </Box>

      {chartData.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box
            id="widget-panel-container"
            style={{
              overflowX: "scroll",
              display: "flex",
              width: "100%",
              height: "300px",
              overflow: "hidden" /* Hide scrollbars */,
              gap: "24px",
            }}
          >
            {chartType !== chartTypes.default && (
              <Box
                id="vr-pie-chart-widget"
                sx={{
                  width: "356px",
                  height: "282px",
                  background: colors.mainWhite,
                  padding: "16px",
                  border: `1px solid ${colors.borderDefault}`,
                  boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
                  borderRadius: "24px",
                }}
              >
                <VRPieChart
                  data={chartData}
                  filtersType={chartType}
                  totalSummaryByType={vrDataSummary.totalSummary[`${selectedChartItem}`]}
                  pieChartSelectionItems={pieChartSelection}
                  selectedChartItem={selectedChartItem}
                  handleChartDataSelection={handleChartDataSelection}
                />
              </Box>
            )}
            {/* render chart widgets */}
            {chartType === chartTypes.termination && (
              <VRLineChart
                chartType={chartType}
                summaryCategoryByDate={summaryCategoryByDate}
                selectedChartItem={selectedChartItem}
              />
            )}
            {chartType === chartTypes.platforms && <VRBarChart data={chartData} coverageData={coverageData} />}
            {chartType === chartTypes.default && chartData[0]?.name !== "emptyDefault" && (
              <VRLineChart
                chartType={chartType}
                summaryCategoryByDate={summaryCategoryByDate}
                defaultSelectedChartItems={defaultSelectedChartItems}
                setDefaultSelectedChartItems={setDefaultSelectedChartItems}
              />
            )}
          </Box>
        </Collapse>
      )}

      {/* small arrows for widgets rotation */}
    </Box>
  );
};

export default VRWidgetsPanel;
