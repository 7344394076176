import React from "react";

export function AdvancedReportIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4 19H8V11H4V19ZM10 19H14V5H10V19ZM16 19H20V13H16V19ZM2 19V11C2 10.45 2.19583 9.97917 2.5875 9.5875C2.97917 9.19583 3.45 9 4 9H8V5C8 4.45 8.19583 3.97917 8.5875 3.5875C8.97917 3.19583 9.45 3 10 3H14C14.55 3 15.0208 3.19583 15.4125 3.5875C15.8042 3.97917 16 4.45 16 5V11H20C20.55 11 21.0208 11.1958 21.4125 11.5875C21.8042 11.9792 22 12.45 22 13V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19Z"
        fill="#B8C1D1"
      />
    </svg>
  );
}

export function AdvancedReportIconHeader() {
  return (
    <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 18.9758H1.14286C0.511675 18.9758 0 19.4875 0 20.1186V31.5472C0 32.1784 0.511675 32.6901 1.14286 32.6901H8C8.63118 32.6901 9.14286 32.1784 9.14286 31.5472V20.1186C9.14286 19.4875 8.63118 18.9758 8 18.9758Z"
        fill="#506077"
      />
      <path
        d="M30.8574 9.83292H24.0003C23.3691 9.83292 22.8574 10.3446 22.8574 10.9758V31.5472C22.8574 32.1784 23.3691 32.6901 24.0003 32.6901H30.8574C31.4886 32.6901 32.0003 32.1784 32.0003 31.5472V10.9758C32.0003 10.3446 31.4886 9.83292 30.8574 9.83292Z"
        fill="#506077"
      />
      <path
        d="M19.4287 0.690063H12.5716C11.9404 0.690063 11.4287 1.20174 11.4287 1.83292V31.5472C11.4287 32.1784 11.9404 32.6901 12.5716 32.6901H19.4287C20.0599 32.6901 20.5716 32.1784 20.5716 31.5472V1.83292C20.5716 1.20174 20.0599 0.690063 19.4287 0.690063Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
