import { CONSTANT } from "../../constants/constants";

export interface ILogData {
  level: string;
  message: string;
}

export const postLog = (logData: ILogData, token: string) => {
  const logEndpoint = CONSTANT.path.fullpath + "/log";
  fetch(logEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(logData),
  })
    .then((response) => {
      if (!response.ok) {
        console.error("Failed to log error:", response.statusText);
      }
    })
    .catch((error) => {
      console.error("Error logging to service:", error);
    });
};
