import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { CreateNotificationDto, ResponseNotificationDto } from "./noticeTypes";
import { CONSTANT } from "../../constants/constants";
import { checkVersion } from "../../infrastructure/infrastructureHelpers";
import { sseEventManager } from "../../infrastructure/sseEventsManager";
import { RootState } from "../../store/store";

const SERVER_URL = CONSTANT.path.host;

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { getState } = api;
  const state = getState() as RootState;
  const token = state.users.loggedUser.token;
  if (!token) {
    return {
      error: {
        status: 401,
        data: { message: "No token available for authentication." },
      },
    } as { error: FetchBaseQueryError };
  }
  const baseQuery = fetchBaseQuery({
    baseUrl: `${SERVER_URL}/notifications`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  });

  const result: any = await baseQuery(args, api, extraOptions);

  if (result.data && "reactAppVersion" in result.data) {
    checkVersion((result.data as any).reactAppVersion);
  }

  return result;
};

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Notifications", "CreatedNotifications", "ReceivedNotifications"],
  endpoints: (builder) => ({
    createNotification: builder.mutation<ResponseNotificationDto, Omit<CreateNotificationDto, "id">>({
      query: (notificationDto) => ({
        url: "",
        method: "POST",
        body: notificationDto,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newNotification } = await queryFulfilled;
          dispatch(
            notificationsApi.util.updateQueryData("getCreatedNotifications", undefined, (draft) => {
              draft.push(newNotification);
            })
          );
        } catch {}
      },
    }),
    getCreatedNotifications: builder.query<ResponseNotificationDto[], void>({
      query: () => ({
        url: "/created",
        method: "GET",
      }),
      providesTags: ["CreatedNotifications"],
    }),
    getReceivedNotifications: builder.query<ResponseNotificationDto[], void>({
      query: () => ({
        url: "/received",
        method: "GET",
      }),
      providesTags: ["ReceivedNotifications"],
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        // wait for the initial query to resolve before proceeding
        await cacheDataLoaded;

        // set up the listener for SSE updates
        const unsubscribe = sseEventManager.subscribe((notification) => {
          updateCachedData((draft) => {
            const index = draft.findIndex((n) => n.id === notification.id);
            if (index !== -1) {
              // Update existing notification
              draft[index] = notification;
            } else {
              // Add new notification
              draft.unshift(notification);
            }
          });
        });

        // clean up the listener when the cache entry is removed
        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
    updateNotification: builder.mutation<ResponseNotificationDto, CreateNotificationDto>({
      query: (updateData) => ({
        url: `/${updateData.id}`,
        method: "PATCH",
        body: updateData,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNotification } = await queryFulfilled;
          dispatch(
            notificationsApi.util.updateQueryData("getCreatedNotifications", undefined, (draft) => {
              const index = draft.findIndex((notification) => notification.id === id);
              if (index !== -1) {
                draft[index] = updatedNotification;
              }
            })
          );
        } catch {}
      },
    }),
    deleteNotification: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData("getCreatedNotifications", undefined, (draft) => {
            const index = draft.findIndex((notification) => notification.id === id);
            if (index !== -1) {
              draft.splice(index, 1);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    markNotificationAsRead: builder.mutation<ResponseNotificationDto[], number[]>({
      query: (ids) => ({
        url: `/mark-as-read`,
        method: "PATCH",
        body: { ids },
      }),
      async onQueryStarted(ids, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNotifications } = await queryFulfilled;
          dispatch(
            notificationsApi.util.updateQueryData("getReceivedNotifications", undefined, (draft) => {
              updatedNotifications.forEach((updatedNotification) => {
                const index = draft.findIndex((notification) => notification.id === updatedNotification.id);
                if (index !== -1) {
                  draft[index] = updatedNotification;
                }
              });
            })
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useCreateNotificationMutation,
  useGetCreatedNotificationsQuery,
  useGetReceivedNotificationsQuery,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useMarkNotificationAsReadMutation,
} = notificationsApi;
