import { useSelector } from "react-redux";
import { OverviewIcon, OverviewIconHeader } from "../IconComponents/OverviewIcon";
import { AdvancedReportIcon, AdvancedReportIconHeader } from "../IconComponents/AdvancedReportIcon";
import { SettingsIcon } from "../IconComponents/SettingsIcon";
import BillingIcon from "../IconComponents/BillingIcon";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import CircleIcon from "@mui/icons-material/Circle";
import {
  AccountsIcon,
  AccountsIconHeader,
  RolesManagementIconHeader,
  UsersManagementIconHeader,
} from "../IconComponents/AccountsIcon";
import { CONSTANT } from "../../constants/constants";
import NotificationsIcon from "../IconComponents/NotificationsIcon";
import { useMemo } from "react";
import { RootState } from "../../store/store";
import TerminalIcon from "@mui/icons-material/Terminal";

// Define the shape of a sidebar item
interface SidebarItem {
  title: string;
  path: string;
  pathArr: string[];
  icon?: JSX.Element | null;
  iconHeader: JSX.Element | null;
  style: string;
  isDisabled: boolean;
  hideHeaderContent: boolean;
  children?: SidebarItem[];
}

export const useGetSidebarData = (): SidebarItem[] => {
  const { loggedUser } = useSelector((state: RootState) => state.users);
  const checkModuleDisabled = (modulesVisibility: number[], { checkForceDisable }: any = false) => {
    if (loggedUser?.modulesVisibility === null) return true;
    let res = true;

    modulesVisibility.forEach((element) => {
      if (
        loggedUser?.modulesVisibility?.includes(element) &&
        !(checkForceDisable && loggedUser?.account_id !== CONSTANT.IIQ_)
      )
        res = false;
    });
    return res;
  };

  return useMemo(
    () => [
      {
        title: "POC Portal",
        path: process.env.REACT_APP_EVERAFTER_LINK || "https://app.everafter.ai/login",
        pathArr: [process.env.REACT_APP_EVERAFTER_LINK || "https://app.everafter.ai/login"],
        icon: <RocketLaunchIcon />,
        iconHeader: <RocketLaunchIcon />,
        style: "test.style1",
        isDisabled: true,
        hideHeaderContent: false,
      },
      {
        title: "Overview",
        path: CONSTANT.PAGES.OVERVIEW.path,
        pathArr: [CONSTANT.PAGES.OVERVIEW.path],
        icon: <OverviewIcon />,
        iconHeader: <OverviewIconHeader />,
        style: "test.style1",
        isDisabled: checkModuleDisabled(CONSTANT.MODULES_PAGES.OVERVIEW.modules),
        hideHeaderContent: false,
      },
      {
        title: "Reports",
        path: CONSTANT.PAGES.PERFORMANCE_REPORT.path,
        pathArr: [CONSTANT.PAGES.PERFORMANCE_REPORT.path, CONSTANT.PAGES.VISITOR_RECOGNITION.path],
        icon: <AdvancedReportIcon />,
        iconHeader: <AdvancedReportIcon />,
        style: "test.style1",
        isDisabled: checkModuleDisabled(CONSTANT.MODULES_PAGES.PERFORMANCE_REPORT.modules),
        hideHeaderContent: false,
        children: [
          {
            title: "Performance",
            path: CONSTANT.PAGES.PERFORMANCE_REPORT.path,
            pathArr: [CONSTANT.PAGES.PERFORMANCE_REPORT.path],
            // icon: <CircleIcon sx={{ fontSize: "10px" }} />,
            iconHeader: <AdvancedReportIconHeader />,
            style: "test.style1",
            isDisabled: false,
            hideHeaderContent: false,
          },
          {
            title: "Visitor recognition",
            path: CONSTANT.PAGES.VISITOR_RECOGNITION.path,
            pathArr: [CONSTANT.PAGES.VISITOR_RECOGNITION.path],
            // icon: <CircleIcon sx={{ fontSize: "10px" }} />,
            iconHeader: <AdvancedReportIconHeader />,
            style: "test.style1",
            isDisabled: false,
            hideHeaderContent: false,
          },
        ],
      },
      {
        title: "Account & Users",
        path: CONSTANT.PAGES.ACCOUNTS.path,
        pathArr: [
          CONSTANT.PAGES.ACCOUNTS.path,
          "/accounts/user_details",
          CONSTANT.PAGES.USERS.path,
          CONSTANT.PAGES.ROLES.path,
        ],
        icon: <AccountsIcon />,
        iconHeader: <AccountsIconHeader />,
        style: "test.style1",
        isDisabled: checkModuleDisabled(CONSTANT.MODULES_PAGES.ACCOUNTS.modules) && !loggedUser?.canCreateAccount,
        hideHeaderContent: true,
        children: [
          {
            title: "Accounts",
            path: CONSTANT.PAGES.ACCOUNTS.path,
            pathArr: [CONSTANT.PAGES.ACCOUNTS.path],
            // icon: <CircleIcon sx={{ fontSize: "10px" }} />,
            iconHeader: <AccountsIconHeader />,
            style: "test.style1",
            isDisabled:
              checkModuleDisabled(CONSTANT.MODULES_PAGES.ACCOUNTS.modules) && !loggedUser?.canCreateAccount,
            hideHeaderContent: true,
          },
          {
            title: "Users management",
            path: CONSTANT.PAGES.USERS.path,
            pathArr: [CONSTANT.PAGES.USERS.path],
            // icon: <CircleIcon sx={{ fontSize: "10px" }} />,
            iconHeader: <UsersManagementIconHeader />,
            style: "test.style1",
            isDisabled: checkModuleDisabled(CONSTANT.MODULES_PAGES.ACCOUNTS.modules, { checkForceDisable: true }),
            hideHeaderContent: true,
          },
          {
            title: "Roles management",
            path: CONSTANT.PAGES.ROLES.path,
            pathArr: [CONSTANT.PAGES.ROLES.path],
            // icon: <CircleIcon sx={{ fontSize: "10px" }} />,
            iconHeader: <RolesManagementIconHeader />,
            style: "test.style1",
            isDisabled: checkModuleDisabled(CONSTANT.MODULES_PAGES.ACCOUNTS.modules),
            hideHeaderContent: true,
          },
        ],
      },
      {
        title: "Billing",
        path: CONSTANT.PAGES.BILLING.path,
        pathArr: [CONSTANT.PAGES.BILLING.path],
        icon: <BillingIcon />,
        iconHeader: <AccountBalanceIcon fontSize="large" />,
        style: "test.style1",
        isDisabled: checkModuleDisabled(CONSTANT.MODULES_PAGES.BILLING.modules),
        hideHeaderContent: true,
      },
      {
        title: "Notifications",
        path: CONSTANT.PAGES.NOTIFICATIONS.path,
        pathArr: [CONSTANT.PAGES.NOTIFICATIONS.path],
        icon: <NotificationsIcon />,
        iconHeader: <NotificationsIcon fontSize="large" />,
        style: "test.style1",
        isDisabled: !(loggedUser?.account_id === CONSTANT.IIQ_),
        hideHeaderContent: true,
      },
      {
        title: "Configurations",
        path: CONSTANT.PAGES.CONFIGURATIONS.path,
        pathArr: [CONSTANT.PAGES.CONFIGURATIONS.path],
        icon: <TerminalIcon />,
        iconHeader: <TerminalIcon fontSize="large" />,
        style: "test.style1",
        isDisabled: checkModuleDisabled(CONSTANT.MODULES_PAGES.BIDDERS_CONFIGURATION.modules),
        // isDisabled: !(loggedUser?.account_id === CONSTANT.IIQ_) && checkModuleDisabled(CONSTANT.MODULES_PAGES.BIDDERS_CONFIGURATION.modules),
        hideHeaderContent: false,
      },
      {
        title: "Supply management",
        path: CONSTANT.PAGES.SUPPLY.path,
        pathArr: [CONSTANT.PAGES.SUPPLY.path],
        icon: <SettingsIcon />,
        iconHeader: <OverviewIconHeader />,
        style: "test.style1",
        isDisabled: true,
        hideHeaderContent: true,
      },
      {
        title: "Integration",
        path: CONSTANT.PAGES.INTEGRATION.path,
        pathArr: [CONSTANT.PAGES.INTEGRATION.path],
        icon: null,
        iconHeader: null,
        style: "test.style1",
        isDisabled: true,
        hideHeaderContent: true,
      },
    ],
    [loggedUser]
  );
};
