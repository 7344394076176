import React, { useRef, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PercentIcon from "@mui/icons-material/Percent";
import BasicPopover from "../../Popover/BasicPopover";
import SettingsPopup from "../../settings/SettingsPopup";
import Grid from "@mui/material/Grid";
import { headerNotificationItem } from "./styles";
import BellIcon from "../../IconComponents/BellIcon";
import auth from "../../../auth/auth-helper";
import SelectMultiLabelsWrapper from "../../SelectLabels/SelectMultiLabelsWrapper";
import { useTogglePopovers } from "../../../hooks/useTogglePopovers";
import SettingsWrapper from "../../settings/SettingsWrapper";
import VersionInfoPopup from "../../settings/VersionInfoPopup";
import Selection from "../../Selection/Selection";
import { fetchAccountImg } from "../../../accounts/api/accounts-api";
import FeedbackForm from "../../Feedbackform/Feedbackform";
import { Badge, Typography } from "@mui/material";
import { CONSTANT } from "../../../constants/constants";
import ReceivedNotifications from "../../../core/notifications/ReceivedNotifications";
import { useGetAccountsQuery, useGetUserByIdQuery } from "../../../accounts/accountsApi";
import { useGetSidebarData } from "../../Sidebar/useGetSidebarData";
import SelectSinglePartner from "../../SelectSinglePartner/SelectSinglePartner";

const Header = ({ feedbackOpen, setFeedbackOpen, setLastNotice, isOpenByBannerClick, setIsOpenByBannerClick }) => {
  const SidebarData = useGetSidebarData();
  const spanRef = useRef();
  let location = useLocation();
  const { partnersSelected } = useSelector((state) => state.role);
  const { currentPage, isPresentationModeEnabled } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const { data: accounts } = useGetAccountsQuery();

  const navigate = useNavigate();
  const { uniqPairs, selectedPct, selectedCurrency } = useSelector((state) => state.report);
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [currentSidebarItem, setCurrentSidebarItem] = useState(null);
  const { loggedUser } = useSelector((state) => state.users);
  const { openMenu: setOpenPopover, closeMenu: closePopover, anchorEl: anchorElPopover } = useTogglePopovers();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const {
    openMenu: setOpenReportSettingsPopover,
    closeMenu: closeReportSettingsPopover,
    anchorEl: anchorElReportSettingsPopover,
  } = useTogglePopovers({ elemRef: spanRef });

  const {
    openMenu: setOpenVersionInfoPopover,
    closeMenu: closeVersionInfoPopover,
    anchorEl: anchorElVersionInfoPopover,
  } = useTogglePopovers({ elemRef: spanRef });

  useEffect(() => {
    fetchImage(partnersSelected);
  }, [partnersSelected, location.pathname]);

  const fetchImage = async (partnersSelected) => {
    if (partnersSelected?.length === 0 || partnersSelected?.length > 1) {
      setPartnerLogo(null);
      return;
    } else {
      const res = await fetchAccountImg(partnersSelected[0]?.acnt_id);
      setPartnerLogo(res ? res : null);
    }
  };

  const openReportSettingsPopover = (e) => {
    closePopover();
    setOpenReportSettingsPopover(e);
  };

  const openVersionInfoPopover = (e) => {
    closePopover();
    setOpenVersionInfoPopover(e);
  };

  const openProfileInfoPopover = (e) => {
    let jwt = auth.isAuthenticated();
    let id = jwt.data?.user?.id;
    navigate(`/accounts/user_details/${id}`);
    closePopover();
  };

  const arr = location.pathname.split("/");
  const userId =
    !isNaN(arr[arr.length - 1]) && arr[1] === "accounts" && arr[2] === "user_details" ? arr[arr.length - 1] : null;
  const accountId =
    !isNaN(arr[arr.length - 1]) && arr[1] === "accounts" && arr[2] !== "user_details" ? arr[arr.length - 1] : null;
  let selectedAccount;
  if (accountId && accounts?.list)
    selectedAccount = accounts.list.find((i) => {
      return i.id === +accountId;
    });

  const { data: userDataById } = useGetUserByIdQuery(userId, {
    skip: !userId,
  });
  useEffect(() => {
    let newSidebarItem;
    SidebarData.forEach((sidebarItem, ind) => {
      if (sidebarItem.children) {
        const currentChild = sidebarItem.children.find((item) => item.path === location.pathname);
        if (currentChild) {
          newSidebarItem = currentChild;
        }
      } else {
        if (sidebarItem.path === location.pathname) {
          newSidebarItem = sidebarItem;
        }
      }
    });
    setCurrentSidebarItem(newSidebarItem);
  }, [location.pathname]);

  const renderHeaderBox = useMemo(() => {
    const defaultHeaderBox = (
      <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }} key={currentSidebarItem?.title}>
        <Box
          sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {currentSidebarItem?.iconHeader &&
            React.cloneElement(currentSidebarItem?.iconHeader, { style: { width: "100%", height: "100%" } })}
        </Box>
        <Typography id="page-title" variant="bodyXLarge700">
          {currentSidebarItem?.title}
        </Typography>
      </Box>
    );
    let res = <></>;

    if (!currentPage?.id) {
      return res; // Ensure currentPage.id is defined
    }

    switch (currentPage.id) {
      case CONSTANT.PAGES.OVERVIEW.id:
      case CONSTANT.PAGES.PERFORMANCE_REPORT.id:
      case CONSTANT.PAGES.VISITOR_RECOGNITION.id: {
        if (!isPresentationModeEnabled) {
          if (partnerLogo !== null) {
            res = <img src={`${partnerLogo}`} style={{ height: "48px", objectFit: "contain" }} />;
          } else if (partnersSelected.length === 1) {
            res = (
              <Typography id="page-title" variant="bodyXLarge700">
                {partnersSelected[0]?.partner_name}
              </Typography>
            );
          } else {
            res = defaultHeaderBox;
          }
        } else {
          res = defaultHeaderBox;
        }
        break;
      }
      case CONSTANT.PAGES.USER_DETAILS.id: {
        res = (
          <Typography id="page-title" variant="bodyXLarge700">
            {userDataById?.account_id?.company}
          </Typography>
        );
        break;
      }
      case CONSTANT.PAGES.ACCOUNTS.id:
      case CONSTANT.PAGES.ACCOUNTS_USERS.id: {
        if (selectedAccount?.company) {
          res = (
            <Typography id="page-title" variant="bodyXLarge700">
              {selectedAccount?.company}
            </Typography>
          );
          break;
        }
      }
      default: {
        res = defaultHeaderBox;
        break;
      }
    }

    return res;
  }, [
    currentPage,
    partnerLogo,
    isPresentationModeEnabled,
    currentSidebarItem,
    partnersSelected,
    userDataById,
    selectedAccount,
  ]);

  let renderActionsBlock = useMemo(() => {
    if (
      currentPage.id !== CONSTANT.PAGES.OVERVIEW.id &&
      currentPage.id !== CONSTANT.PAGES.PERFORMANCE_REPORT.id &&
      currentPage.id !== CONSTANT.PAGES.VISITOR_RECOGNITION.id &&
      currentPage.id !== CONSTANT.PAGES.PERFORMANCE_REPORT.id &&
      currentPage.id !== CONSTANT.PAGES.CONFIGURATIONS.id
    )
      return <></>;
    return (
      <Grid container direction="row" sx={{ justifyContent: "flex-end" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          sx={{ alignItems: "flex-end" }}
        >
          <Box
            sx={{
              marginRight: "12px",
              marginBottom: "3px",
            }}
          >
            {currentPage.id === CONSTANT.PAGES.CONFIGURATIONS.id ? (
              <SelectSinglePartner />
            ) : (
              <SelectMultiLabelsWrapper />
            )}
          </Box>
        </Box>

        {selectedCurrency && currentPage.id === CONSTANT.PAGES.OVERVIEW.id ? (
          <Box id="currency-button">
            <Box
              sx={{
                ...headerNotificationItem,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1Small600">{selectedCurrency}</Typography>
            </Box>
          </Box>
        ) : null}
        {/* Notification icon not in usage */}
        {false ? (
          <Box sx={{ margin: "0px 5px" }}>
            <Box sx={headerNotificationItem}>
              <BellIcon />
            </Box>
          </Box>
        ) : null}

        <Box
          sx={{
            ...headerNotificationItem,
            border: "none",
            padding: "0px",
            paddingLeft: "5px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {currentPage.id === CONSTANT.PAGES.OVERVIEW.id && (
            <>
              {uniqPairs.length > 1 ? (
                <span style={{ fontSize: "16px", fontWeight: 700 }}>
                  <Box
                    id="percentage-select"
                    sx={{
                      ...headerNotificationItem,
                      minWidth: "120px",
                      padding: "7px 11px",
                    }}
                  >
                    <Selection />
                  </Box>
                </span>
              ) : uniqPairs.length === 1 ? (
                <Box id="selected-percent" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <PercentIcon style={{ color: "grey", fontSize: "18px" }} />
                  <span style={{ fontSize: "16px", fontWeight: 700 }}>
                    {Boolean(selectedPct) ? selectedPct : ""}
                  </span>
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Grid>
    );
  }, [currentPage, uniqPairs]);

  const imageAvatar = loggedUser.avatarUrl || CONSTANT.defaultAvatarUrl;

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "72px",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
          }}
        >
          {renderHeaderBox}
        </Grid>
        <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Grid item>{renderActionsBlock}</Grid>
          <Grid item sx={{ marginRight: "10px" }}>
            <Badge
              color="error"
              badgeContent={unreadMessagesCount}
              max={CONSTANT.OVERFLOW_UNREAD_MESSAGES}
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: "11px",
                },
              }}
            >
              <ReceivedNotifications
                unreadMessagesCount={unreadMessagesCount}
                setUnreadMessagesCount={setUnreadMessagesCount}
                setLastNotice={setLastNotice}
                isOpenByBannerClick={isOpenByBannerClick}
                setIsOpenByBannerClick={setIsOpenByBannerClick}
              />
            </Badge>
          </Grid>

          <Grid item sx={{ cursor: "pointer" }}>
            <ListItemAvatar ref={spanRef} id="avatar-icon" onClick={setOpenPopover}>
              <Avatar src={imageAvatar} />
            </ListItemAvatar>
          </Grid>
        </Grid>
      </Grid>

      {/* All Options popover */}
      <BasicPopover
        anchorEl={anchorElPopover}
        setAnchorEl={setOpenPopover}
        closePopover={closePopover}
        popoverWidth={"300px"}
      >
        <SettingsWrapper
          setAnchorEl={setOpenPopover}
          closePopover={closePopover}
          openReportSettingsPopover={openReportSettingsPopover}
          openVersionInfoPopover={openVersionInfoPopover}
          openProfileInfoPopover={openProfileInfoPopover}
        />
      </BasicPopover>

      {/* Report settings popover */}
      <BasicPopover
        anchorEl={anchorElReportSettingsPopover}
        setAnchorEl={setOpenReportSettingsPopover}
        closePopover={closeReportSettingsPopover}
        popoverWidth={"700px"}
      >
        <SettingsPopup closeReportSettingsPopover={closeReportSettingsPopover} />
      </BasicPopover>

      {/* Version info popover */}
      <BasicPopover
        anchorEl={anchorElVersionInfoPopover}
        setAnchorEl={setOpenVersionInfoPopover}
        closePopover={closeVersionInfoPopover}
        popoverWidth={"500px"}
      >
        <VersionInfoPopup />
      </BasicPopover>

      {/* Feedback popup */}
      <FeedbackForm feedbackOpen={feedbackOpen} setFeedbackOpen={setFeedbackOpen} />
    </>
  );
};

export default Header;
