import { useSelector, useDispatch } from "react-redux";
import AreaChartWidget from "./AreaChartWidget";
import {
  setActiveAreaChartTypes,
  setAgregationTypeId,
  toggleWidgetChartCurrencyToDisplay,
} from "../../advancedReportSlice";
import { Box, IconButton, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { CONSTANT } from "../../../../../constants/constants";
import ExpandLessIcon from "../../../../../components/IconComponents/ExpandLessIcon2";
import ExpandMoreIcon from "../../../../../components/IconComponents/ExpandMoreIcon2";
import { colors } from "../../../../../theme";
import { useEffect, useState } from "react";
import SelectItems from "../../../../../components/SelectItems/SelectItems";
import CheckboxWrapper from "../../../../../components/CustomCheckbox/Checkbox";

const MAX_GRAPHS_TO_SHOW = 2;
export const createGraphSelectedItemBody = (item) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
      <Typography variant="body1Small400">{item.title}</Typography>
      {item.bodyIcon}
    </Box>
  );
};

const selectionAggregationDataTypes = Object.values(CONSTANT.ADVANCED_REPORT.AGREGATION_DATA_CHART_TYPES).map(
  (item) => {
    return { ...item, body: createGraphSelectedItemBody(item) };
  }
);
const chartTypeItems = CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES;
export const selectionChartTypeItemIds = Object.values(chartTypeItems).map((item) => item.id);
export const selectionChartTypeItems = Object.values(chartTypeItems).map((item) => {
  return { ...item, body: createGraphSelectedItemBody(item) };
});

const setSelectedLabel = (selectedItems) => {
  const selectedLabel = selectedItems
    .filter((item) => item.isSelected)
    .map((item) => {
      if (item.prefix && item.prefix.trim() !== "") {
        return `${item.title} ${item.prefix}`;
      }
      return item.title;
    })
    .join(", ");
  return selectedLabel ? selectedLabel : "";
};

const calculateWidth = (label) => {
  const minWidth = 160;
  const charWidth = 7; // Estimated width per character in pixels
  const calculatedWidth = Math.max(minWidth, label.length * charWidth + 90);
  return `${calculatedWidth}px`;
};

const transformCurrencyToGraphItems = (rawCurrency, currenciesToDisplay) => {
  return rawCurrency.map((currency, index) => ({
    id: currency,
    name: currency.toLowerCase(),
    title: currency,
    isSelected: currenciesToDisplay[currency] ?? false,
    body: createGraphSelectedItemBody({ title: currency }),
    areaChartType: currency,
  }));
};

const AreaChartWrapper = (props) => {
  const { expandAreaChart, setExpandAreaChart } = props;
  const dispatch = useDispatch();
  const { partnersList, partnersSelected } = useSelector((state) => state.role);
  let { rawCurrency, widgetChart } = useSelector((state) => state.advancedReport);
  const { currenciesToDisplay, activeAreaChartTypes, agregationTypeId } = widgetChart;
  const selectionCurrencyItems = transformCurrencyToGraphItems(rawCurrency, currenciesToDisplay);
  const [currencySelectWidth, setCurrencySelectWidth] = useState(
    calculateWidth(setSelectedLabel(selectionCurrencyItems))
  );
  const selectedCharts = selectionChartTypeItems.map((item) => {
    return {
      ...item,
      isSelected: activeAreaChartTypes.includes(item.id),
    };
  });
  const [selectWidth, setSelectWidth] = useState(
    calculateWidth(
      setSelectedLabel(
        selectedCharts.filter((item) => item.id !== CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id)
      )
    )
  );
  const [graphItems, setGraphItems] = useState(selectedCharts);
  const [currencyItems, setCurrencyItems] = useState(selectionCurrencyItems);
  const isAgregationTypesSelcetionPresent = partnersList.length > 1;
  const isAgregationTypesSelcetionDesabled = !isAgregationTypesSelcetionPresent || partnersSelected.length <= 1;

  const [agregationTypes, setAgregationTypes] = useState(
    isAgregationTypesSelcetionDesabled
      ? [{ ...CONSTANT.ADVANCED_REPORT.AGREGATION_DATA_CHART_TYPES.SUMMARY, isSelected: true }]
      : selectionAggregationDataTypes.map((item) => {
          return {
            ...item,
            isSelected: agregationTypeId === item.id,
          };
        })
  );
  const [impressionsItem, setImpressionsItem] = useState({
    ...chartTypeItems.IMPRESSIONS,
    isSelected: activeAreaChartTypes.includes(chartTypeItems.IMPRESSIONS.id),
  });

  useEffect(() => {
    const selectedCharts = graphItems.filter((item) => item.isSelected);
    const selectedCurrencies = currencyItems.filter((item) => item.isSelected);
    if (!selectedCharts.length) {
      setGraphItems(selectionChartTypeItems);
      dispatch(setActiveAreaChartTypes([chartTypeItems.REVENUE.id]));
    }
    if (!selectedCurrencies.length) {
      const updatedItems = selectionCurrencyItems.map((item, index) => ({
        ...item,
        isSelected: index === 0,
      }));
      const updatedSelectedCurrencies = {};

      currencyItems.forEach((item, index) => {
        updatedSelectedCurrencies[item.id] = index === 0;
      });
      setCurrencyItems(updatedItems);
      dispatch(toggleWidgetChartCurrencyToDisplay(updatedSelectedCurrencies));
    }
  }, []);

  const handleChangeGraphItems = (event) => {
    const {
      target: { value },
    } = event;

    // Ensure value is always an array of selected IDs
    const selectedIds = Array.isArray(value) ? value : [value];
    if (impressionsItem.isSelected) {
      selectedIds.push(impressionsItem.id);
    }
    const validSelectedIds = selectedIds.filter((id) => selectionChartTypeItemIds.includes(id));
    dispatch(setActiveAreaChartTypes(validSelectedIds));
    // Update the isSelected property of selectionChartTypeItems
    const updatedItems = selectionChartTypeItems.map((item) => ({
      ...item,
      isSelected: selectedIds.includes(item.id),
    }));

    const selectedLabel = setSelectedLabel(updatedItems);
    const newWidth = calculateWidth(selectedLabel);
    if (newWidth !== selectWidth) {
      setSelectWidth(newWidth);
    }

    setGraphItems(updatedItems);
  };

  const handleChangeCurrencyItems = (event) => {
    const {
      target: { value },
    } = event;

    // Ensure value is always an array of selected IDs
    const selectedIds = Array.isArray(value) ? value : [value];

    // Update the isSelected property of selectionChartTypeItems
    const updatedItems = selectionCurrencyItems.map((item) => ({
      ...item,
      isSelected: selectedIds.includes(item.id),
    }));

    const selectedLabel = setSelectedLabel(updatedItems);
    const newWidth = calculateWidth(selectedLabel);
    if (newWidth !== currencySelectWidth) {
      setCurrencySelectWidth(newWidth);
    }

    const updatedSelectedCurrencies = {};
    updatedItems.forEach((item) => {
      updatedSelectedCurrencies[item.id] = item.isSelected;
    });

    dispatch(toggleWidgetChartCurrencyToDisplay(updatedSelectedCurrencies));

    setCurrencyItems(updatedItems);
  };

  const handleImpressionsSelection = (isChecked) => {
    setImpressionsItem((prev) => ({ ...prev, isSelected: isChecked }));
    let items = [...graphItems];
    if (isChecked) {
      const impressions = chartTypeItems.IMPRESSIONS;
      impressions.isSelected = isChecked;
      items.push(impressions);
      setGraphItems(items);
    } else {
      items = graphItems.filter((item) => item.id !== CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id);
      setGraphItems(items);
    }
    const selectedIds = items.filter((item) => item.isSelected).map((item) => item.id);
    dispatch(setActiveAreaChartTypes(selectedIds));
  };

  const handleAgregationType = (event) => {
    const {
      target: { value },
    } = event;
    const updatedItems = selectionAggregationDataTypes.map((item) => ({
      ...item,
      isSelected: value === item.id,
    }));
    dispatch(setAgregationTypeId(value));
    setAgregationTypes(updatedItems);
  };

  return (
    <Box
      id="area-chart-wrapper"
      sx={{
        width: expandAreaChart ? "100%" : "calc(100% - 374px)",
        height: "480px",
        padding: "16px",
        background: colors.mainWhite,
        border: `1px solid ${colors.borderDefault}`,
        borderRadius: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography id="title" variant="body1Small600">Analytics</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            {isAgregationTypesSelcetionPresent && (
              <SelectItems
                id="agregation-type-selection"
                items={agregationTypes}
                handleChangeItem={handleAgregationType}
                isSingleSelection={true}
                selectWidth="147px"
                menuWidth="145px"
                disabled={isAgregationTypesSelcetionDesabled}
              />
            )}
            <IconButton
              id="expand-chart-button"
              onClick={() => setExpandAreaChart(!expandAreaChart)}
              style={{ background: expandAreaChart ? colors.gray200 : colors.gray75, borderRadius: "100px" }}
            >
              {expandAreaChart ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <SelectItems
            id="chart-selection"
            items={graphItems.filter(
              (item) => item.id !== CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id
            )}
            selectTitle="Graph Type"
            handleChangeItem={handleChangeGraphItems}
            selectWidth={selectWidth}
            disableItemCondition={graphItems.filter((item) => item.isSelected).length >= MAX_GRAPHS_TO_SHOW}
          />
          <SelectItems
            id="currency-selection"
            items={currencyItems}
            selectTitle="Currency"
            handleChangeItem={handleChangeCurrencyItems}
            selectWidth={currencySelectWidth}
            menuWidth="150px"
          />
          <Box
            id="impression-switch"
            onClick={() => handleImpressionsSelection(!impressionsItem.isSelected)}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "8px",
              gap: "12px",
              borderRadius: "10px",
              margin: "0",
              textAlign: "left",
              pointerEvents:
                !impressionsItem.isSelected &&
                graphItems.filter((item) => item.isSelected).length >= MAX_GRAPHS_TO_SHOW
                  ? "none"
                  : "auto",
            }}
          >
            <CheckboxWrapper id='impression-checkbox' checked={impressionsItem.isSelected} />
            <Typography
              sx={{
                opacity:
                  !impressionsItem.isSelected &&
                  graphItems.filter((item) => item.isSelected).length >= MAX_GRAPHS_TO_SHOW
                    ? 0.38
                    : 1,
              }}
              variant="body1Small400"
            >
              Impressions
            </Typography>
          </Box>
        </Box>
      </Box>
      <AreaChartWidget
        {...props}
        currenciesToDisplay={currenciesToDisplay}
        activeAreaChartTypes={activeAreaChartTypes}
        isSummary={
          agregationTypes.filter(
            (item) =>
              item.id === CONSTANT.ADVANCED_REPORT.AGREGATION_DATA_CHART_TYPES.SUMMARY.id &&
              item.isSelected === true
          ).length
        }
      />
    </Box>
  );
};
export default AreaChartWrapper;
