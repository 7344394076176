import { colors } from "../theme";

export const tableContainerStl = {
  padding: "10px 20px 0px 20px",
  background: "#FFFFFF",
  border: "1px solid #E0E2E7",
  boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
  borderRadius: "8px",
};

export const textFieldInputStl = {
  width: "16rem",
  "& .MuiInputBase-input": {
    padding: "0.875rem",
    "& .MuiInputBase-root": {
      borderRadius: "0.5rem",
    },
  },
};

export const sideBoardWrapperStl = {
  width: "16rem",
};

export const tableStl = {
  "& .MuiTableCell-body": {
    padding: "0.675rem 0px 0.675rem 0px",
    color: "secondary.mainDark",
    fontSize: "0.875rem",
  },
  "& .MuiTableCell-head": {
    padding: "0.675rem 0px 0.675rem 0px",
    color: "#8590A0",
    fontSize: "0.875rem",
  },
};

export const tableCellListStl = {
  border: "1px solid #E0E2E7",
  borderRadius: "8px",
  padding: "4px 0px 4px 24px",
};

export const dataGridSx = {
  "& .MuiDataGrid-columnHeaders": {
    "&:hover .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
    borderBottom: "1px solid #1BB2FF",
  },
  cursor: "pointer",
  fontSize: "14px",
  "& .MuiDataGrid-columnHeaderTitle": {
    color: colors.mainDarkPrimary40,
    fontWeight: 600,
  },
  "& .MuiDataGrid-row": {
    borderBottom: `1px solid ${colors.mainDarkPrimary10}`,
  },
  border: `1px solid ${colors.mainDarkPrimary10} !important`,
  borderRadius: "8px",
  "& .MuiDataGrid-cell:hover": {
    color: "primary.main",
  },
  // "& .MuiDataGrid-columnHeader": {
  //  borderBottom: `1px solid ${colors.mainDarkPrimary10} !important`,
  // },
  padding: "0 24px 0 24px",
  "& .MuiDataGrid-row.Mui-hovered": {
    backgroundColor: "transparent",
  },
  // Take out the hover colour
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "transparent",
  },
};

export const pageContainerStyle = {
  display: "flex",
  flexDirection: "column",
  margin: "1rem 0rem",
  padding: "10px 0",
  borderTop: "1px solid #E0E2E7",
  gap: "10px",
};

export const tabsContainerStyle = {
  marginRight: 0,
  display: "flex",
  justifyContent: "space-between",
  gap: "0.625rem",
  width: "100%",
  alignItems: "flex-end",
};

export const tableWrapperBoxCard = {
  padding: "20px 20px 0px 20px",
  background: "#FFFFFF",
  border: "1px solid #E0E2E7",
  boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
  borderRadius: "8px",
};
