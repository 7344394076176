import { useEffect, useRef, useState } from "react";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

export const BasicTooltip = (props) => {
  const { delay = 1100, customStyles = {}, customStylesTooltipElement = {} } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return (
    <Tooltip
      id="basic-tooltip"
      placement="top"
      enterDelay={delay}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      PopperProps={{
        modifiers: [
          {
            name: "flip",
            enabled: false,
          },
        ],
        sx: {
          zIndex: 101101,
        },
        style: {
          zIndex: 101101,
        },
      }}
      title={
        <Box
          id="tooltip-text"
          className="tooltip-container"
          style={{
            fontSize: "16px",
            whiteSpace: "normal",
            display: "block",
            textAlign: "center",
            ...customStyles,
          }}
          sx={{ zIndex: 101101 }}
        >
          {props.tooltipText}
        </Box>
      }
    >
      <Box sx={customStylesTooltipElement} className="tooltip-element">
        {props.children}
      </Box>
    </Tooltip>
  );
};

export const TooltipMultiselectGroup = (props) => {
  let { delay = 1100, customStyles = {} } = props;
  return (
    <Tooltip
      placement="top"
      enterDelay={delay}
      title={<Box style={{ fontSize: "16px", ...customStyles }}>{props.tooltipText}</Box>}
      disableInteractive
    >
      {props.children}
    </Tooltip>
  );
};
