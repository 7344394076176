import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { CONSTANT } from "../constants/constants";

import auth from "../auth/auth-helper";
import { IUser, IUserExtraProps } from "./users/interfaces";
import { checkVersion } from "../infrastructure/infrastructureHelpers";
import { RootState } from "../store/store";
import { el } from "date-fns/locale";

const SERVER_URL = CONSTANT.path.host;
interface IGetUsers {
  users: IUser[];
  rolesPerUser: any;
}

export interface IAccount {
  id: number;
  company: string;
  partners: string;
  users: number;
  virtualAccount: number;
  creationDate: string;
  createdBy: string;
  status: string;
  parentAccount: number;
}

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: async (args, api, extraOptions) => {
    const { getState } = api;
    const state = getState() as RootState;
    const token = state.users.loggedUser.token;
    if (!token) {
      return {
        error: {
          status: 401,
          data: { message: "No token available for authentication." },
        },
      } as { error: FetchBaseQueryError };
    }
    const baseQuery = fetchBaseQuery({
      baseUrl: `${SERVER_URL}/accounts`,
      prepareHeaders: (headers) => {
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      },
    });

    // Pass the args and other parameters to the fetchBaseQuery
    return baseQuery(args, api, extraOptions);
  },
  tagTypes: ["User", "Users", "Account", "Module", "Role"],
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getAllUsers: builder.query<IGetUsers, void>({
      query: () => `user_details`,
      providesTags: ["Users"],
    }),
    getUserById: builder.query<IUserExtraProps, string | undefined>({
      query: (id: string | undefined) => `user_details/${id}`,
      transformResponse: (response: { user: any }) => response.user,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),
    updateUserById: builder.mutation<IUser, { id: string; user: IUser }>({
      query: ({ id, user }) => ({
        url: `update_user/${id}`,
        method: "PATCH",
        body: user,
      }),
      transformResponse: (response: { user: any }) => response.user,
      invalidatesTags: (result, error, { id }) => [{ type: "User", id }, "Users"],
    }),
    updateStatusById: builder.mutation<null, { id: string; status: number }>({
      query: ({ id, status }) => ({
        url: `update_account/status/${id}`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: ["Account", "Users"],
    }),
    getAccounts: builder.query<any, void>({
      // getAccounts: builder.query<IAccount[], void>({
      query: () => ``,
      transformResponse: (response: any) => {
        checkVersion(response.reactAppVersion);
        return response;
      },
      providesTags: ["Account"],
    }),
    getModulePermissions: builder.query<number, void>({
      query: () => `module_permisson`,
    }),
    createOrUpdateVirtualAccount: builder.mutation({
      query: ({ data, includeUserSection, id }) => {
        const endpoint = includeUserSection ? "create_virtual_account" : `update_virtual_account/${id}`;
        return {
          url: endpoint,
          method: "POST",
          body: data,
        };
      },
    }),
    createRole: builder.mutation({
      query: (data) => {
        return {
          url: "create_role",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Account"],
    }),
    updateRoleEntity: builder.mutation({
      query: (data) => {
        return {
          url: "update_role_entity",
          method: "POST",
          body: data,
        };
      },
    }),
    getRoleByRoleId: builder.query({
      query: (id: string | undefined) => `role_details/${id}`,
      // transformResponse: (response: { user: any }) => response.user,
    }),
    deleteUserById: builder.mutation<void, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const patchResultAllUsers = dispatch(
          accountsApi.util.updateQueryData("getAllUsers", undefined, (draft) => {
            draft.users = draft.users.filter((user) => user.id !== +id);
          })
        );
        const patchResultAccounts = dispatch(
          accountsApi.util.updateQueryData("getAccounts", undefined, (draft) => {
            // Iterate through all account objects and filter out the deleted user
            draft.list.forEach((account: any) => {
              account.users = account.users.filter((user: IUser) => user?.id !== parseInt(id, 10));
            });
          })
        );
        // Remove the specific `getUserById` cache for the deleted user
        const patchResultUserById = dispatch(accountsApi.util.updateQueryData("getUserById", id, () => undefined));

        try {
          await queryFulfilled; // Wait for the server response to confirm the deletion
        } catch {
          // If the deletion fails, undo both changes
          patchResultAllUsers.undo();
          patchResultUserById.undo();
          patchResultAccounts.undo();
        }
      },
      invalidatesTags: ["Users"],
    }),
    createAccount: builder.mutation({
      query: (data) => ({
        url: `create_account`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Account"],
    }),
  }),
});

// Export hooks for usage in functional components
export const useGetAllUsersQuery = accountsApi.endpoints.getAllUsers.useQuery;
export const useGetUserByIdQuery = accountsApi.endpoints.getUserById.useQuery;
export const useUpdateUserByIdMutation = accountsApi.endpoints.updateUserById.useMutation;
export const useGetAccountsQuery = accountsApi.endpoints.getAccounts.useQuery;
export const useGetModulePermissionsQuery = accountsApi.endpoints.getModulePermissions.useQuery;
export const useCreateOrUpdateVirtualAccount = accountsApi.endpoints.createOrUpdateVirtualAccount.useMutation;
export const {
  useCreateRoleMutation,
  useGetRoleByRoleIdQuery,
  useUpdateRoleEntityMutation,
  useDeleteUserByIdMutation,
  useUpdateStatusByIdMutation,
  useCreateAccountMutation,
} = accountsApi;
