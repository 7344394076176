import { Box, useMediaQuery } from "@mui/material";
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { renderDefaultIcon } from "../../../../components/chartTemplates/stackedBarChart-utils";
import { convertLocal } from "../../../../utils";
import { colors } from "../../../../theme";
import { VRChartCustomTooltip } from "./VRChartCustomTooltip";
import { ChartData } from "../../types";

const MAX_BARS_ON_CHART_WITHOUT_SCROLL = 25;
const MAX_BARS_ON_CHART_WITHOUT_ROTATE_LABEL = 15;
const TEXT_POSITIONS = {
  x: 25,
  y: 10,
};
const TEXT_POSITIONS_ROTATED = {
  x: 10,
  y: 10,
};
const SHIFT_DX = -15;
const RenderCustomizedLabel = (props: any) => {
  let { shouldRotateItems, matches } = props;
  let { value, viewBox } = props;
  let textYPos = shouldRotateItems ? TEXT_POSITIONS_ROTATED.y : TEXT_POSITIONS.y;
  let textXPos = shouldRotateItems ? TEXT_POSITIONS_ROTATED.x : TEXT_POSITIONS.x;

  let dx = shouldRotateItems ? -SHIFT_DX : matches ? SHIFT_DX : 0;
  let rotate = shouldRotateItems ? " rotate(-45)" : matches ? "" : " rotate(-45)";
  let testStl = { fontSize: "0.8rem", fontWeight: 700 };

  return (
    <text
      id="bar-chart-label"
      textAnchor="middle"
      dominantBaseline="middle"
      // transform={`translate(${viewBox.x + 10},${viewBox.y - textYPos})`}
      transform={`translate(${viewBox.x + textXPos},${viewBox.y - textYPos})${rotate}`}
      dx={dx}
      fill="#131330"
      // fontSize={12}
      // fontWeight={600}
      style={testStl}
    >
      {convertLocal(value, 2, true)}
    </text>
  );
};
const VRBarChart = ({ data, coverageData }: { data: ChartData[]; coverageData: ChartData[] }) => {
  const matches = useMediaQuery("(min-width:1520px)");
  let shouldRotateItems = data.length > MAX_BARS_ON_CHART_WITHOUT_ROTATE_LABEL;
  let sortData = data.sort((a: ChartData, b: ChartData) => Number(b.value) - Number(a.value));
  const coverageDataMap = new Map(coverageData.map((item) => [item.name, item.value]));
  // let coverageData = sortData.map((platform)=> {return {
  //   name: platform.name,
  //   value:  totalSummary platform.value}})
  const reachedBarsMax = sortData.length > MAX_BARS_ON_CHART_WITHOUT_SCROLL;
  const barChartWidth =
    (100 / MAX_BARS_ON_CHART_WITHOUT_SCROLL) * (sortData.length - MAX_BARS_ON_CHART_WITHOUT_SCROLL);
  return (
    <Box
      sx={{
        width: "calc(100% - 382px)",
        padding: "0 16px",
        height: "280px",
        background: "#FFFFFF",
        border: "1px solid #E0E2E7",
        boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
        borderRadius: "24px",
        // position: "relative",
        overflowX: reachedBarsMax ? "scroll" : "none",
        overflowY: "hidden",
      }}
    >
      <Box
        id="vr-bar-chart"
        sx={{
          width: reachedBarsMax ? `calc(100% + ${barChartWidth}%)` : "100%",
          height: "100%",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            barGap={35}
            barCategoryGap={50}
            data={sortData}
            margin={{
              top: shouldRotateItems ? 45 : 25,
              right: 10,
              left: 0,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey="name"
              tickLine={{ stroke: "none" }}
              interval={0}
              tick={(props) => {
                const { x, y } = props;
                const name = props.payload.value?.trim().toLowerCase();
                //*** Render Platfor Icon */
                // let translateX = -20;
                // let translateY = -5;
                // let height = 20;
                // let width = 60;
                // let svgName = name;
                // try {
                //   const Icon = require(`../../../../assets/svgIcons/${svgName}.svg`).ReactComponent;
                //   return (
                //     <g transform={`translate(${translateX} ${translateY})`}>
                //       <Icon id={`item_id_${name}`} x={x} y={y} height={height} width={width} />
                //     </g>
                //   );
                // } catch (err) {
                return renderDefaultIcon(x, y, name);
                // }
              }}
            />
            {/* <YAxis /> */}
            <Tooltip cursor={false} content={<VRChartCustomTooltip coverageDataMap={coverageDataMap} />} />
            {/* <Legend
              align="left"
              verticalAlign="top"
              iconType="circle"
              wrapperStyle={{
                top: 5,
              }}
            /> */}
            <Bar
              dataKey="value"
              fill={colors.lightBlue}
              radius={[8, 8, 0, 0]}
              // minPointSize={5}
              barSize={25}
            >
              <LabelList
                dataKey="value"
                content={<RenderCustomizedLabel matches={matches} shouldRotateItems={shouldRotateItems} />}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default VRBarChart;
