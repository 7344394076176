import { colors } from "../../theme";

export const link = {
  display: "flex",
  alignItems: "cover",
  cursor: "pointer",
  borderRadius: "30px",
  "&:hover": {
    backgroundColor: "#e8f5fe",
    color: "#50b7f5",
    transition: "color 100ms ease-out",
  },
  "& > h2": {
    fontWeight: 700,
    fontSize: "20px",
    marginRight: "20px",
  },
  "& .MuiSvgIcon-root": {
    padding: "20px",
  },
};

export const sideBarContainer = (isShown) => {
  return {
    width: isShown ? "282px" : "64px",
    minWidth: isShown ? "282px" : "64px",
    padding: isShown ? "32px 16px 40px 16px" : "32px 12px 40px 12px",
    transition: "150ms",
    height: "100%",
    position: "fixed",
    top: "0px",
    left: "0px",
    backgroundColor: "secondary.main",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 99999,
  };
};

export const sideBarExpandButton = (isShown) => {
  return {
    width: "20px",
    height: "20px",
    backgroundColor: !isShown && colors.mainWhite,
    top: "6px",
    right: isShown ? "16px" : "-22px",
    borderRadius: "50%",
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    padding: "0px, 2px",
    justifyContent: "center",
    alignUtems: "center",
    gap: "8px",
    "& #sidebar-expand-btn": {
      transform: isShown ? "rotate(-90deg)" : "rotate(90deg)",
    },
  };
};

export const menuItemActiveCollapsed = (isOnTop) => {
  return {
    color: "#fff",
    fontWeight: 500,
    // color: 'text.lightGrey',
    borderLeft: isOnTop ? null : 3,
    borderColor: "#1BB2FF",
    // boxShadow: " 0 0 6px hsl(210 4% 61%)",
    svg: {
      path: {
        fill: (theme) => "#1BB2FF",
        fillOpacity: 1,
      },
    },
  };
};

export const menuItem = {
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "8px 2px 8px 8px",
  gap: "16px",
  borderRadius: "8px",
  width: "100%",
  color: colors.navy100,
};

export const menuItemActive = {
  color: colors.mainWhite,
  background: colors.netural500,
  svg: {
    path: {
      fill: (theme) => colors.mainWhite,
    },
  },
};
export const menuChildItem = {
  padding: "4px 16px",
  alignItems: "flex-start",
};

export const menuItemCollapsed = (isOnTop = false) => {
  return {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textIndent: null,
    marginBottom: isOnTop ? null : "1rem",
    marginLeft: isOnTop ? "1rem" : "1px",
    marginRight: isOnTop ? "1rem" : null,
    color: "rgba(255,255,255,0.5)",
    height: "4.375rem",
    fontSize: "1.2rem",
    borderLeft: 3,
    borderColor: "transparent",
    svg: {
      path: {
        fill: (theme) => "#fff",
        fillOpacity: 0.5,
        // fill: (theme) => theme.palette.primary.main,
      },
    },
    "&:hover": {
      transition: "color 500ms ease-out",
      color: "rgba(255,255,255,1)",
      svg: {
        path: {
          fill: (theme) => "#fff",
          fillOpacity: 1,
        },
      },
    },
  };
};

export const logoContainer = {
  display: "flex",
  alignItems: "center",
  // height: "32px",
  // width: "100px",
};

export const wrapperMenuItemLeft = {
  height: "100vh",
  width: "50px",
  backgroundColor: "#131330",
  borderTopRightRadius: "1rem",
  borderBottomRightRadius: "1rem",
  paddingTop: "20px",
  fontSize: "20px",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

export const innerMenuItemLeft = {
  padding: "10px",
  backgroundColor: "#131330",
  //borderRadius: "0.5rem",
  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  cursor: "pointer",
};

export const iconSx = (sideBarIsOpen) => ({
  left: sideBarIsOpen ? "-2.125rem" : "-3.125rem",
  position: "absolute",
  //top: "30%",
});
