export function AccountsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 12C10.9 12 9.95833 11.6083 9.175 10.825C8.39167 10.0417 8 9.1 8 8C8 6.9 8.39167 5.95833 9.175 5.175C9.95833 4.39167 10.9 4 12 4C13.1 4 14.0417 4.39167 14.825 5.175C15.6083 5.95833 16 6.9 16 8C16 9.1 15.6083 10.0417 14.825 10.825C14.0417 11.6083 13.1 12 12 12ZM4 18V17.2C4 16.6333 4.14583 16.1125 4.4375 15.6375C4.72917 15.1625 5.11667 14.8 5.6 14.55C6.63333 14.0333 7.68333 13.6458 8.75 13.3875C9.81667 13.1292 10.9 13 12 13C13.1 13 14.1833 13.1292 15.25 13.3875C16.3167 13.6458 17.3667 14.0333 18.4 14.55C18.8833 14.8 19.2708 15.1625 19.5625 15.6375C19.8542 16.1125 20 16.6333 20 17.2V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18ZM6 18H18V17.2C18 17.0167 17.9542 16.85 17.8625 16.7C17.7708 16.55 17.65 16.4333 17.5 16.35C16.6 15.9 15.6917 15.5625 14.775 15.3375C13.8583 15.1125 12.9333 15 12 15C11.0667 15 10.1417 15.1125 9.225 15.3375C8.30833 15.5625 7.4 15.9 6.5 16.35C6.35 16.4333 6.22917 16.55 6.1375 16.7C6.04583 16.85 6 17.0167 6 17.2V18ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8042 9.02083 14 8.55 14 8C14 7.45 13.8042 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9792 6.19583 10.5875 6.5875C10.1958 6.97917 10 7.45 10 8C10 8.55 10.1958 9.02083 10.5875 9.4125C10.9792 9.80417 11.45 10 12 10Z"
        fill="#B8C1D1"
      />
    </svg>
  );
}
export function AccountsIconHeader() {
  return (
    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.7885 18.9416C23.5411 17.2606 22.6994 15.724 21.416 14.6105C20.1327 13.4969 18.4927 12.8803 16.7936 12.8724H15.2101C13.5111 12.8803 11.8711 13.4969 10.5878 14.6105C9.30441 15.724 8.4627 17.2606 8.21531 18.9416L7.4563 24.2487C7.43205 24.4202 7.44807 24.5951 7.50309 24.7594C7.55811 24.9237 7.65062 25.0729 7.7733 25.1952C8.07095 25.4929 9.89704 27.0109 16.0034 27.0109C22.1097 27.0109 23.9313 25.4988 24.2334 25.1952C24.3561 25.0729 24.4486 24.9237 24.5036 24.7594C24.5587 24.5951 24.5747 24.4202 24.5504 24.2487L23.7885 18.9416Z"
        fill="#C0C7CF"
      />
      <path
        d="M9.39215 13.691C7.95379 15.017 7.01793 16.7994 6.74305 18.7362L6.19239 22.5462C1.77226 22.5164 0.43283 20.9091 0.209591 20.5817C0.123299 20.4627 0.0617187 20.3277 0.0284885 20.1846C-0.00474167 20.0414 -0.00894839 19.8931 0.0161169 19.7483L0.343534 17.9028C0.514122 16.9384 0.91476 16.0292 1.51148 15.2526C2.1082 14.4759 2.8834 13.8546 3.77136 13.4412C4.65932 13.0279 5.63385 12.8348 6.61233 12.8783C7.5908 12.9218 8.54436 13.2006 9.39215 13.691Z"
        fill="#506077"
      />
      <path
        d="M31.9835 19.7483C32.0085 19.8931 32.0043 20.0414 31.9711 20.1846C31.9379 20.3277 31.8763 20.4627 31.79 20.5817C31.5667 20.9091 30.2273 22.5164 25.8072 22.5462L25.2565 18.7362C24.9816 16.7994 24.0458 15.017 22.6074 13.691C23.4552 13.2006 24.4088 12.9218 25.3872 12.8783C26.3657 12.8348 27.3403 13.0279 28.2282 13.4412C29.1162 13.8546 29.8914 14.4759 30.4881 15.2526C31.0848 16.0292 31.4855 16.9384 31.656 17.9028L31.9835 19.7483Z"
        fill="#506077"
      />
      <path
        d="M9.64493 10.4169C9.27031 10.9482 8.77297 11.3812 8.19518 11.6792C7.61738 11.9771 6.97621 12.1312 6.32611 12.1284C5.67761 12.1284 5.0384 11.9743 4.46114 11.6787C3.88389 11.3832 3.38511 10.9548 3.00593 10.4287C2.62675 9.90261 2.37801 9.29394 2.28021 8.65286C2.18241 8.01178 2.23835 7.35664 2.44342 6.74142C2.6485 6.1262 2.99683 5.56852 3.45972 5.11433C3.92261 4.66014 4.4868 4.32246 5.1058 4.1291C5.7248 3.93573 6.38089 3.89224 7.02 4.00218C7.65911 4.11213 8.26294 4.37238 8.78174 4.76148C8.63252 5.34502 8.55752 5.94506 8.5585 6.54739C8.55964 7.91234 8.93544 9.2508 9.64493 10.4169Z"
        fill="#506077"
      />
      <path
        d="M29.766 8.03565C29.7664 8.57322 29.6608 9.1056 29.4553 9.60232C29.2497 10.0991 28.9483 10.5504 28.5682 10.9305C28.188 11.3106 27.7367 11.6121 27.24 11.8176C26.7433 12.0232 26.2109 12.1288 25.6733 12.1284C25.0232 12.1312 24.382 11.9771 23.8042 11.6792C23.2265 11.3812 22.7291 10.9482 22.3545 10.4169C23.064 9.2508 23.4398 7.91234 23.4409 6.54739C23.4419 5.94507 23.3669 5.34502 23.2177 4.76148C23.8257 4.30544 24.5488 4.02773 25.3058 3.95947C26.0628 3.89121 26.8238 4.0351 27.5036 4.37501C28.1835 4.71493 28.7552 5.23743 29.1548 5.88398C29.5544 6.53053 29.766 7.27558 29.766 8.03565Z"
        fill="#506077"
      />
      <path
        d="M15.9999 12.1283C19.0822 12.1283 21.5809 9.62963 21.5809 6.54734C21.5809 3.46506 19.0822 0.96637 15.9999 0.96637C12.9176 0.96637 10.4189 3.46506 10.4189 6.54734C10.4189 9.62963 12.9176 12.1283 15.9999 12.1283Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
export function UsersManagementIconHeader() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2554 16.6159C19.7764 16.6159 23.4414 12.9509 23.4414 8.42988C23.4414 3.90885 19.7764 0.243835 15.2554 0.243835C10.7344 0.243835 7.06934 3.90885 7.06934 8.42988C7.06934 12.9509 10.7344 16.6159 15.2554 16.6159Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3857 30.7733C15.8333 29.1123 14.8837 26.8812 14.8837 24.4299C14.8837 21.9279 15.8735 19.6567 17.4809 17.9838C16.7561 17.9287 16.0119 17.8989 15.2558 17.8989C10.3114 17.8989 5.93414 19.1358 3.21637 20.9888C1.14307 22.4027 0 24.1992 0 26.085V28.2446C0 28.9144 0.266419 29.5588 0.741209 30.0336C1.216 30.5069 1.85898 30.7748 2.53023 30.7748L17.3857 30.7733Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.186 16.6159C19.8727 16.6159 16.3721 20.1166 16.3721 24.4299C16.3721 28.7432 19.8727 32.2438 24.186 32.2438C28.4993 32.2438 32 28.7432 32 24.4299C32 20.1166 28.4993 16.6159 24.186 16.6159ZM25.3023 23.3136V21.8252C25.3023 21.209 24.8022 20.7089 24.186 20.7089C23.5698 20.7089 23.0697 21.209 23.0697 21.8252V23.3136H21.5814C20.9652 23.3136 20.4651 23.8137 20.4651 24.4299C20.4651 25.046 20.9652 25.5461 21.5814 25.5461H23.0697V27.0345C23.0697 27.6507 23.5698 28.1508 24.186 28.1508C24.8022 28.1508 25.3023 27.6507 25.3023 27.0345V25.5461H26.7907C27.4069 25.5461 27.907 25.046 27.907 24.4299C27.907 23.8137 27.4069 23.3136 26.7907 23.3136H25.3023Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
export function RolesManagementIconHeader() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2554 16.6159C19.7764 16.6159 23.4414 12.9509 23.4414 8.42988C23.4414 3.90885 19.7764 0.243835 15.2554 0.243835C10.7344 0.243835 7.06934 3.90885 7.06934 8.42988C7.06934 12.9509 10.7344 16.6159 15.2554 16.6159Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3857 30.7733C15.8333 29.1123 14.8837 26.8812 14.8837 24.4299C14.8837 21.9279 15.8735 19.6567 17.4809 17.9838C16.7561 17.9287 16.0119 17.8989 15.2558 17.8989C10.3114 17.8989 5.93414 19.1358 3.21637 20.9888C1.14307 22.4027 0 24.1992 0 26.085V28.2446C0 28.9144 0.266419 29.5588 0.741209 30.0336C1.216 30.5069 1.85898 30.7748 2.53023 30.7748L17.3857 30.7733Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.186 16.6159C19.8727 16.6159 16.3721 20.1166 16.3721 24.4299C16.3721 28.7432 19.8727 32.2438 24.186 32.2438C28.4993 32.2438 32 28.7432 32 24.4299C32 20.1166 28.4993 16.6159 24.186 16.6159ZM25.3023 23.3136V21.8252C25.3023 21.209 24.8022 20.7089 24.186 20.7089C23.5698 20.7089 23.0697 21.209 23.0697 21.8252V23.3136H21.5814C20.9652 23.3136 20.4651 23.8137 20.4651 24.4299C20.4651 25.046 20.9652 25.5461 21.5814 25.5461H23.0697V27.0345C23.0697 27.6507 23.5698 28.1508 24.186 28.1508C24.8022 28.1508 25.3023 27.6507 25.3023 27.0345V25.5461H26.7907C27.4069 25.5461 27.907 25.046 27.907 24.4299C27.907 23.8137 27.4069 23.3136 26.7907 23.3136H25.3023Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
