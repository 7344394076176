// OverviewPage
export const btnGroupWrapper = {
  display: "flex",
  borderRadius: "5px",
  "& button": {
    cursor: "pointer",
    minWidth: "7.1875rem",
    border: "1px solid #E5E5E5",
    padding: "0.8125rem 3.125rem",
    fontSize: "1.125rem",
    borderTopRightRadius: "5px",
    borderLeft: "none",
    borderRight: "none",
    backgroundColor: "#FAFAFA",
    "&:first-of-type": {
      borderTopLeftRadius: "5px",
      borderRight: "none",
      borderLeft: "1px solid #E5E5E5",
      borderBottomLeftRadius: "5px",
    },
    "&:last-child": {
      borderTopRightRadius: "5px",
      borderLeft: "none",
      backgroundColor: "#FAFAFA",
      borderBottomRightRadius: "5px",
      borderRight: "1px solid #E5E5E5",
    },
    "&:hover": {
      backgroundColor: "rgba(27, 178, 255, 0.05)",
      transition: "600ms ease-in",
    },
  },
};

export const btnWrapper = {
  margin: "0rem 1.125rem 1rem 0rem",
  display: "flex",
  border: "1px solid #E5E5E5",
  borderLeft: "none",
  borderRight: "none",
};

export const singleBtn = {
  cursor: "pointer",
  minWidth: "7.1875rem",
  padding: "0.875rem 3.125rem",
  fontSize: "1.125rem",
  lineHeight: "22px",
  borderRadius: "0px",
  borderLeft: "none",
  borderRight: "none",
  fontWeight: 400,
  color: "secondary.main",
  "&:hover": {
    // borderBottom: "1px solid #F0F0F0",
    // backgroundColor: "#F5F5F5",
    transition: "100ms ease-in",
  },
};

export const dateContainer = {
  // minWidth: "7.1875rem",
  // marginRight: "6%",
  padding: "0.875rem 0rem",
  fontSize: "1.125rem",
  lineHeight: "22px",
  color: "secondary.main",
  fontWeight: 700,
  textAlign: "right",
};

export const activeBtn = {
  color: "#1BB2FF",
  borderBottom: "2px solid #1BB2FF",
};

export const stylesWidget = {
  container: {
    minWidth: "270px",
    height: "375px",
    boxShadow: "1px 3px 5px #d3d3d3",
    borderRadius: "12px",
    padding: "12px 16px",
    color: "#606060",
    textAlign: "left",
  },
  checkboxWrapper: {
    color: "#001738",
    fontSize: "10px",
    "& .MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root":
      {
        padding: 0,
        margin: 0,
      },
  },
  tickSize: {
    transform: "scale(0.6)",
  },
};

export const sectionWrapper = {
  padding: "0rem 0rem 0rem 1.25rem",
};

export const toolTipStyle = {
  wrapper: {
    backgroundColor: "#fff",
    width: 170,
    padding: "5px",
    borderRadius: 12,
    color: "#000",
    fontSize: "1.1rem",
    whiteSpace: "pre-line",
  },
  hint: {
    marginTop: 7,
    marginBottom: 0,
    fontSize: "11px",
    color: "grey",
  },
  pargraph: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: "11px",
  },
};
