import React from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Popper,
} from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import { useGetAllConfiguration, useUpdatePartnersConfigurationById } from "./configurationsApi";
import { colors } from "../../theme";

interface ConfigurationStatusMenuProps {
  currentStatus: number | null;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onStatusClick: (newStatus: number) => void;
}

const statusOptions = [
  {
    value: CONSTANT.ACCOUNT_STATUS.ACTIVE.value,
    label: CONSTANT.ACCOUNT_STATUS.ACTIVE.label,
    color: "green",
  },

  {
    value: CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value,
    label: CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.label,
    color: "red",
  },
];

const ConfigurationStatusMenu: React.FC<ConfigurationStatusMenuProps> = ({
  currentStatus,
  anchorEl,
  onClose,
  onStatusClick,
}) => {
  const [updatePartnersConfigurationById, { isLoading }] = useUpdatePartnersConfigurationById();
  const { isFetching } = useGetAllConfiguration();

  const handleStatusChange = async (newStatus: number) => {
    onStatusClick(newStatus);
  };

  return (
    <Popper open={anchorEl !== null} anchorEl={anchorEl}>
      <>
        {isLoading ?? isFetching ? (
          <CircularProgress sx={{ width: "300px", margin: "8px" }} />
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={true}
            onClose={onClose}
            slotProps={{
              paper: {
                elevation: 3,
                sx: {
                  borderRadius: "8px",
                  overflow: "hidden",
                  mt: 1.5,
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                },
              },
            }}
          >
            {statusOptions.map((option) => {
              return (
                <MenuItem
                  key={option.value}
                  selected={option.value === currentStatus}
                  onClick={() => handleStatusChange(option.value)}
                  sx={{
                    bgcolor: option.value === currentStatus ? colors.backgroundBaseTertiary : "transparent",
                    borderRadius: "8px",
                    margin: "4px 8px",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "unset", marginRight: "8px" }}>
                    <Box
                      sx={{
                        width: "14px",
                        height: "14px",
                        bgcolor: option.color,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="bodyLargeRegular">{option.label}</Typography>}
                    sx={{ color: option.color, fontWeight: option.value === currentStatus ? "bold" : "normal" }}
                  />
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </>
    </Popper>
  );
};

export default ConfigurationStatusMenu;
