import { createSlice } from "@reduxjs/toolkit";
import {
  convertToChartData,
  getAllTimeFirstDate,
  prepareTemplate,
  prepareTemplateMonthlyGrouped,
  prepareTemplateQuarterlyGrouped,
  prepareTemplateAggregatedGrouped,
} from "./utils";
import { getDateAgo, getPrevMonthDates } from "../utils";
import { getSummaryReport } from "./summaryThunks/getSummaryReport";
import { applyPctAndCurrencyToSummaryChart } from "./summaryThunks/applyPctAndCurrencyToSummaryChart";
import { CONSTANT } from "../constants/constants";

const initialState = {
  records: [],
  dataRaw: [],
  processing: false,
  status: "idle",
  rawCurrency: [],
  rawPct: [],
  selectedPct: null,
  selectedCurrency: null,
  allTimeDateStart: null,
  allTimeDateEnd: null,
};

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    setSummaryEntryParametersConstancy(state, action) {
      const { selectedPct, selectedCurrency } = action.payload?.overviewParameters;
      state.selectedPct = selectedPct;
      state.selectedCurrency = selectedCurrency;
    },
    resetSummary() {
      return initialState;
    },
    resetRawPctRawCurrencySummaryChart(state, action) {
      state.rawCurrency = [];
      state.rawPct = [];
      // state.selectedPct = null;
      // state.selectedCurrency = null;
    },
    resetSummaryChartRecords(state, action) {
      state.records = [];
      state.dataRaw = [];
      state.rawCurrency = [];
      state.rawPct = [];
      state.selectedPct = null;
      state.selectedCurrency = null;
      state.processing = false;
      state.status = "idle";
    },
    applySummaryReportPctMode(state, action) {
      let data = action.payload.split("-");

      state.selectedCurrency = data[0];
      state.selectedPct = +data[1];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSummaryReport.pending, (state, action) => {
        // data for summary area chart
        state.status = "loading";
        state.processing = true;
      })
      .addCase(getSummaryReport.fulfilled, (state, action) => {
        if (!action.payload.result.reportSummary.length) {
          state.status = "succeeded";
          state.records = [];
          state.dataRaw = [];
          state.processing = false;
          return;
        }
        state.status = "succeeded";
        let stats = action.payload.result.reportSummary;

        state.selectedPct = action.payload.reportSelectedPct;
        state.selectedCurrency = action.payload.reportSelectedCurrency;

        if (action.payload.reportRawPct.length > 1 || action.payload.reportSelectedCurrency.length > 1)
          stats = stats.filter((i) => i.pct === +state.selectedPct && i.currency === state.selectedCurrency);
        let activeRangeDate = action.payload.activeRangeDate;
        // state.pct = stats[0].pct;
        let reportDateStart = undefined;
        let chartLastDate = undefined;

        //get dates
        let date = new Date();
        let dateCopy = new Date(date);
        if (activeRangeDate === -10) {
          state.allTimeDateStart = getAllTimeFirstDate(action.payload.result.reportSummary);
          let yesterday = new Date();
          if (action.payload.dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH)
            yesterday.setMonth(yesterday.getMonth() - 1);
          else yesterday.setDate(yesterday.getDate() - 1);
          state.allTimeDateEnd = +yesterday;
          reportDateStart = { startDate: state.allTimeDateStart };
          chartLastDate = state.allTimeDateEnd;
        } else {
          if (activeRangeDate === -1) {
            reportDateStart = {
              startDate: action.payload.customDateRange.customDateStart,
            };
            chartLastDate = +new Date(action.payload.customDateRange.customDateEnd);
          } else {
            if (activeRangeDate === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value) {
              let { start, end } = getPrevMonthDates();
              reportDateStart = { startDayNumber: 1, startDate: start };
              chartLastDate = new Date(end);
            } else {
              reportDateStart = getDateAgo(date, activeRangeDate);
              chartLastDate = dateCopy.setDate(date.getDate() - 1);
            }
          }
        }
        let template = undefined;
        // Convert raw data to appropriate object
        switch (action.payload.dateGroupingMode) {
          case CONSTANT.DATE_GROUP_MODES.MONTH: {
            // Check if startDate is already in the desired format or needs to be parsed
            let reportDateStartValue = reportDateStart.startDate;
            let parsedDate;
            if (isNaN(Date.parse(reportDateStartValue))) {
              parsedDate = new Date(reportDateStartValue);
            } else {
              parsedDate = new Date(reportDateStartValue);
            }
            let year = parsedDate.getFullYear();
            let month = ("0" + (parsedDate.getMonth() + 1)).slice(-2); // Ensure month is 2 digits
            let modifiedStartDate = `${year}-${month}-01`;

            template = prepareTemplateMonthlyGrouped(modifiedStartDate, chartLastDate);
            break;
          }
          case CONSTANT.DATE_GROUP_MODES.QUARTER: {
            template = prepareTemplateQuarterlyGrouped(reportDateStart.startDate, chartLastDate);
            break;
          }
          case CONSTANT.DATE_GROUP_MODES.AGGREGATED: {
            // start date and end dates are the same
            template = prepareTemplateAggregatedGrouped(reportDateStart.startDate, chartLastDate);
            break;
          }
          default: {
            template = prepareTemplate(reportDateStart.startDate, chartLastDate);
          }
        }
        //quarterly previous period
        state.records = convertToChartData(template, stats, state.selectedPct);
        // state.records = convertToChartData(template, stats, stats[0].pct);
        // state.records = convertToChartData(reportDateStart, chartLastDate, stats, stats[0].pct);
        state.dataRaw = action.payload.result.reportSummary;
        // state.dataRaw = stats;
        // state.currency = stats[0].currency;
        state.processing = false;
      })
      .addCase(getSummaryReport.rejected, (state, action) => {
        state.records = [];
        state.dataRaw = [];
        if (action.payload === "report not found") {
          // state.status = "not_found";
          state.error = action.payload;
        } else {
          state.processing = false;
          // state.status = "failed";
          state.error = action.payload;
        }
      });

    builder
      .addCase(applyPctAndCurrencyToSummaryChart.pending, (state, action) => {
        // data for summary area chart
        state.status = "loading";
        state.processing = true;
      })
      .addCase(applyPctAndCurrencyToSummaryChart.fulfilled, (state, action) => {
        if (!action.payload.result.length) {
          state.status = "succeeded";
          state.records = [];
          state.dataRaw = [];
          state.processing = false;
          return;
        }
        let stats = action.payload.result;

        //*** Solution for PCT for Strict Mode
        // if (!selectedPct) {
        //   setParametersConstancyWrapper("selectedPct", "", "", action.payload.reportSelectedPct);
        //   setParametersConstancyWrapper("selectedCurrency", "", "", action.payload.reportSelectedCurrency);
        //   selectedPct = action.payload.reportSelectedPct;
        //   selectedCurrency = action.payload.reportSelectedCurrency;
        // }
        // if (action.payload.settingsStrictMode) {
        //   state.selectedPct = selectedPct;
        //   state.selectedCurrency = selectedCurrency;
        // } else {
        //   state.selectedPct = action.payload.reportSelectedPct;
        //   state.selectedCurrency = action.payload.reportSelectedCurrency;
        // }
        //*** Solution for PCT for Strict Mode

        //*** Solution without Strict Mode for PCT
        state.selectedPct = action.payload.reportSelectedPct;
        state.selectedCurrency = action.payload.reportSelectedCurrency;
        //*** Solution without Strict Mode for PCT

        stats = stats.filter(
          (i) =>
            i.pct === +action.payload.reportSelectedPct && i.currency === action.payload.reportSelectedCurrency
        );

        let activeRangeDate = action.payload.activeRangeDate;
        let reportDateStart = undefined;
        let chartLastDate = undefined;

        //get dates
        let date = new Date();
        let dateCopy = new Date(date);
        switch (activeRangeDate) {
          case -10: {
            reportDateStart = { startDayNumber: 1, startDate: state.allTimeDateStart };
            chartLastDate = state.allTimeDateEnd;
            break;
          }
          case -1: {
            reportDateStart = {
              startDate: action.payload.customDateRange.customDateStart,
            };
            chartLastDate = +new Date(action.payload.customDateRange.customDateEnd);
            break;
          }
          case CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value: {
            let { start, end } = getPrevMonthDates();
            reportDateStart = { startDayNumber: 1, startDate: start };
            chartLastDate = new Date(end);
            break;
          }
          default: {
            reportDateStart = getDateAgo(date, activeRangeDate);
            chartLastDate = dateCopy.setDate(date.getDate() - 1);
          }
        }

        let template = undefined;
        // Convert raw data to appropriate object
        switch (action.payload.dateGroupingMode) {
          case CONSTANT.DATE_GROUP_MODES.MONTH: {
            template = prepareTemplateMonthlyGrouped(reportDateStart.startDate, chartLastDate);
            break;
          }
          case CONSTANT.DATE_GROUP_MODES.QUARTER: {
            template = prepareTemplateQuarterlyGrouped(reportDateStart.startDate, chartLastDate);
            break;
          }
          case CONSTANT.DATE_GROUP_MODES.AGGREGATED: {
            template = prepareTemplateAggregatedGrouped(reportDateStart.startDate, chartLastDate);
            break;
          }
          default: {
            template = prepareTemplate(reportDateStart.startDate, chartLastDate);
          }
        }

        state.records = convertToChartData(template, stats, state.selectedPct);

        // state.currency = stats[0].currency;
        state.status = "succeeded";
        state.processing = false;
      })
      .addCase(applyPctAndCurrencyToSummaryChart.rejected, (state, action) => {
        if (action.payload === "report not found") {
          // state.status = "not_found";
          state.error = action.payload;
        } else {
          state.processing = false;
          // state.status = "failed";
          state.error = action.payload;
        }
      });
  },
});

export const {
  setSummaryEntryParametersConstancy,
  resetRawPctRawCurrencySummaryChart,
  applySummaryReportPctMode,
  resetSummaryChartRecords,
  resetSummary,
} = summarySlice.actions;

export default summarySlice.reducer;
