import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANT } from "../../constants/constants";
import auth from "../../auth/auth-helper";

const stl = {
  descriptionLine: {
    textAlign: "left",
    color: "rgb(1, 45, 76)",
    fontSize: "14px",
    margin: "10px 0px 16px",
    padding: "10px",
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    borderRadius: "5px",
  },
};
const diffMinutes = (date1, date2) => {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();
  return (d2 - d1) / 60000; // return diff in min
};

const diffSeconds = (date1, date2) => {
  let startDate = new Date(date1).getTime();
  let endDate = new Date(date2).getTime();
  return (endDate - startDate) / 1000; // return diff in sec
};

const SessionTimeout = ({ timeToIdle = CONSTANT.sessionIdle }) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState(["click"]);
  // const [second, setSecond] = useState(0);
  // const [isOpen, setOpen] = useState(false);
  const isAuthenticated = Boolean(auth.isAuthenticated());

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  let leftSecondRef = useRef();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, timeToIdle); // set session idle time
  };

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);
    let start = new Date().toString();
    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 1; // 1 min range for handling (active check)
      // const popTime = 0; //show warning popup in 1 min before session timeout

      let now = new Date().toString();
      let minPast = diffMinutes(start, now);

      // show warinig popup with ability to continue work or confirm logout
      // not in usage - woriking functionality
      // let leftSecond = 60 - diffSeconds(start, now);
      // leftSecondRef.current = leftSecond;
      // if (minPast >= popTime) {
      //   setSecond(leftSecond);
      //   setOpen(true);
      // }

      if (minPast == maxTime) {
        // logout
        clearInterval(warningInactiveInterval.current);
        // setOpen(false);// not in usage
        sessionStorage.removeItem("lastTimeStamp");
        auth.clearJWT(() => navigate("/login"));
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated) {
      timeStamp = new Date().toString();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    let tch = () => {
      // RESET and Run Again
      timeChecker();
    };
    tch();
    // setOpen(false);//not in usage
  }, [isAuthenticated]);

  // handle close popup
  const handleClose = () => {
    // setOpen(false);//not in usage
    resetTimer();
  };

  useEffect(() => {
    //SUBSCRIBE
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      //UNSUBSCRIBE
      clearTimeout(startTimerInterval.current);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [resetTimer, events, timeChecker]);

  return null; // should be removed, if warning popup is in usage

  // not in usage - working functionality
  // if (!isOpen) {
  //   return null;
  // }

  // show modal and handleclose func to close and continue work
  // return (
  //   <Dialog
  //     sx={{
  //       '.MuiPaper-root': {
  //         margin: 0,
  //         width: 520,
  //         padding: '26px',
  //         textAlign: "center",
  //         borderRadius: '1.4rem',
  //         boxShadow: '0 .4rem .8rem 0 rgba(0, 56, 93, .1)'
  //       }
  //     }}
  //     open={isOpen} onClose={handleClose}>
  //     <DialogTitle sx={{ fontSize: "1.25rem" }}>{"IIQ Bid Enhacement monitoring system"}</DialogTitle>
  //     <div
  //       style={{ ...stl.descriptionLine, fontSize: "1rem" }}
  //     >Logout in a {leftSecondRef.current} seconds....
  //     </div>
  //     <div style={{ textAlign: "left" }}>
  //       Would you like to continue work?
  //     </div>

  //     <DialogActions>
  //       <Button
  //         onClick={() => {

  //           clearInterval(warningInactiveInterval.current);
  //           setOpen(false);
  //           sessionStorage.removeItem("lastTimeStamp");
  //           auth.clearJWT(() => navigate('/login'))
  //         }}
  //         color="secondary"
  //         autoFocus="autoFocus"
  //       >
  //         Leave
  //       </Button>
  //       <Button onClick={handleClose} sx={{ color: "green" }}>
  //         Yes
  //       </Button>
  //     </DialogActions>
  //   </Dialog>
  // );
};

export default SessionTimeout;
