import React, { useEffect, useState } from "react";
import { CategoriesType, FilterGroup } from "../../../../parametersConstancy/parametersConstancyTypes";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { colors } from "../../../../theme";
import EmptyDataIcon from "../../../../components/IconComponents/EmptyDataIcon";
import GroupFilterHandler from "./GroupFilterHandler";
import ConfirmationDialog from "../../../../components/Dialogs/ConfirmationDialog";
import { DialogTypes } from "./FilterGroupDialogForm";
import { mappingFiltersToFilterGroup } from "../../../../parametersConstancy/parametersConstancyUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { CONSTANT } from "../../../../constants/constants";
import { useGetFilterGroupsQuery } from "../../../../parametersConstancy/parametersContancyFetches";
import CustomStepper from "../../../../components/CustomStepper/CustomStepper";

interface FilterGroupDialogStepperProps {
  dialogActionsType: string;
  filterGroup?: FilterGroup | null;
  options: CategoriesType;
  values: CategoriesType;
  isOnlyOnePartner: boolean;
  partnersOptions: CategoriesType;
  partnersValues: CategoriesType;
  handleClickDeleteGroup: (filterGroup: FilterGroup | null) => void;
  handleClickCreateGroup: (filterGroup: FilterGroup) => void;
  handleClickUpdateGroup: (filterGroup: FilterGroup) => void;
  enableFilters?: boolean;
  type: string;
  id?: string;
}

const STEPS_ENUM = {
  GROUP_NAME: { id: 0, name: "Group name" },
  PARTNERS: { id: 1, name: "Partners" },
  FILTERS: { id: 2, name: "Filters" },
};

const MAX_GROUP_NAME_LENGTH = 40;

const FilterGroupDialogStepper: React.FC<FilterGroupDialogStepperProps> = ({
  dialogActionsType = DialogTypes.CREATE,
  filterGroup = null,
  options,
  values,
  isOnlyOnePartner,
  partnersOptions,
  partnersValues,
  handleClickDeleteGroup,
  handleClickCreateGroup,
  handleClickUpdateGroup,
  enableFilters = true,
  type,
  id,
}) => {
  const { data: filterGroupsRaw } = useGetFilterGroupsQuery();
  const currentUserAccountId = useSelector((state: RootState) => state.users.loggedUser?.account_id);

  const steps = Object.values(STEPS_ENUM)
    .filter(
      (step) => !isOnlyOnePartner || currentUserAccountId === CONSTANT.IIQ_ || STEPS_ENUM.PARTNERS.id !== step.id
    )
    .filter((step) => step.id !== STEPS_ENUM.FILTERS.id || enableFilters);

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [groupName, setGroupName] = useState<string>(filterGroup?.name ?? "");
  const [nextDisabled, setNextDisabled] = useState(false);
  const [nameDuplicated, setNameDuplicated] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [filtersValues, setFiltersValues] = useState<CategoriesType>(values);
  const [filtersOptions, setFiltersOptions] = useState<CategoriesType>(options);
  const [partnersUpdatedValues, setPartnersUpdatedValues] = useState<CategoriesType>(partnersValues);
  const [partnersUpdatedOptions, setPartnersUpdatedOptions] = useState<CategoriesType>(partnersOptions);
  const finalStepActionButtonTitle = dialogActionsType === DialogTypes.CREATE ? "Create group" : "Save changes";

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  useEffect(() => {
    setNameDuplicated(
      filterGroupsRaw?.find((g) => g.name === groupName) !== undefined && dialogActionsType === DialogTypes.CREATE
    );
    setNextDisabled(
      groupName?.length === 0 ||
        (nameDuplicated && dialogActionsType === DialogTypes.CREATE) ||
        (type === CONSTANT.FILTER_GROUP_TYPE.PARTNER &&
          partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base]?.length === 0)
    );
  }, [groupName, nameDuplicated, partnersUpdatedValues]);

  const handleStepAction = () => {
    if (activeStepIndex === steps.length - 1) {
      const createdOrUpdatedFilterGroup: FilterGroup = mappingFiltersToFilterGroup(
        groupName,
        enableFilters ? filtersValues : {},
        enableFilters ? filtersOptions : {},
        partnersUpdatedValues,
        type,
        filterGroup?.id
      );
      dialogActionsType === DialogTypes.CREATE
        ? handleClickCreateGroup(createdOrUpdatedFilterGroup)
        : handleClickUpdateGroup(createdOrUpdatedFilterGroup);
    } else {
      setActiveStepIndex(activeStepIndex + 1);
    }
  };

  const handleStep = (stepIndex: number) => () => {
    if (groupName?.length > 0) setActiveStepIndex(stepIndex);
  };

  return (
    <Box sx={{ width: "100%", display: "flex", height: "532px", flexDirection: "column" }}>
      <CustomStepper
        steps={steps}
        activeStepIndex={activeStepIndex}
        nextDisabled={nextDisabled}
        handleStepAction={handleStepAction}
        handleStep={handleStep}
        finalStepActionButtonTitle={finalStepActionButtonTitle}
        deleteButton={
          dialogActionsType === DialogTypes.EDIT ? (
            <Button
              id="delete-group-btn"
              variant="clearAll"
              size="customMedium"
              sx={{
                textAlign: "left",
                color: `${colors.statusErrorDefault}`,
                ":hover": {
                  background: colors.mainWhite,
                },
              }}
              onClick={handleOpenConfirmationDialog}
            >
              Delete group
            </Button>
          ) : (
            <Box />
          )
        }
      >
        {/* Step content */}
        {steps[activeStepIndex].id === STEPS_ENUM.GROUP_NAME.id && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <TextField
              id="group-name-input"
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
              inputProps={{ maxLength: MAX_GROUP_NAME_LENGTH }}
              fullWidth
              required
              placeholder="Type here"
            />
            {nameDuplicated && (
              <Typography id="group-name-input-error" color="red" variant="bodySmallRegular">
                Group with this name already exists, please enter a different name.
              </Typography>
            )}
            <Typography id="group-name-input-hint" variant="bodySmallRegular">
              Number of characters: {MAX_GROUP_NAME_LENGTH - groupName.length}
            </Typography>
            <Box sx={{ alignSelf: "center", marginTop: "34px" }}>
              <EmptyDataIcon />
            </Box>
          </Box>
        )}
        {steps[activeStepIndex].id === STEPS_ENUM.PARTNERS.id && (
          <Box>
            <GroupFilterHandler
              options={partnersUpdatedOptions}
              values={partnersUpdatedValues}
              setFiltersOptions={setPartnersUpdatedOptions}
              setFiltersValues={setPartnersUpdatedValues}
              isReadOnlyView={dialogActionsType === DialogTypes.CREATE}
              withPartners={true}
              id="partner-group"
            />
          </Box>
        )}
        {steps[activeStepIndex].id === STEPS_ENUM.FILTERS.id && (
          <GroupFilterHandler
            options={filtersOptions}
            values={filtersValues}
            setFiltersOptions={setFiltersOptions}
            setFiltersValues={setFiltersValues}
            isReadOnlyView={dialogActionsType === DialogTypes.CREATE}
            withPartners={false}
            id="filter-group"
          />
        )}
      </CustomStepper>

      {/* Confirmation dialog */}
      <ConfirmationDialog
        isOpen={openConfirmationDialog}
        title="Delete group"
        body="Are you sure about deleting this group?"
        confirmButtonTitle="Yes, delete group"
        declineButtonTitle="Cancel"
        onConfirm={() => {
          handleCloseConfirmationDialog();
          handleClickDeleteGroup(filterGroup);
        }}
        onDecline={handleCloseConfirmationDialog}
      />
    </Box>
  );
};

export default FilterGroupDialogStepper;
