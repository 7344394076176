import Box from "@mui/material/Box";
import { SxProps, Typography } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import SegmentControl, { ISegmentControlOption } from "../SegmentControl/SegmentControl";
import { CSSProperties, useEffect, useState } from "react";
import { getTypeByValue } from "./utils";
import DatePicker from "../DatePicker/DatePicker";
import GroupingMenu from "./GroupingMenu";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { colors } from "../../theme";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const dateRangeOptions: ISegmentControlOption[] = [
  { id: CONSTANT.DATE_RANGE_TYPES.YESTARDAY.type, name: CONSTANT.DATE_RANGE_TYPES.YESTARDAY.title },
  { id: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type, name: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.title },
  { id: CONSTANT.DATE_RANGE_TYPES.MTD.type, name: CONSTANT.DATE_RANGE_TYPES.MTD.title },
  { id: CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.type, name: CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.title },
  { id: CONSTANT.DATE_RANGE_TYPES.YTD.type, name: CONSTANT.DATE_RANGE_TYPES.YTD.title },
  { id: CONSTANT.DATE_RANGE_TYPES.ALL_TIME.type, name: CONSTANT.DATE_RANGE_TYPES.ALL_TIME.title },
  { id: CONSTANT.DATE_RANGE_TYPES.CUSTOM.type, name: CONSTANT.DATE_RANGE_TYPES.CUSTOM.title },
];

interface DateRangePanelProps {
  renderSelectedDate: (params: { rangeTimeParam?: number; isShortFormat?: boolean }) => string;
  isActiveButton: (rangeTimeParam: number) => CSSProperties | null;
  handleDateRangeButtons: (type: string) => void;
  isDisabled: boolean;
  dateGroupingMode: string | number;
  filterBase?: string;
  active_range_date: string | number;
  matches: boolean;
  datePicker?: React.ReactNode;
  hourPicker?: React.ReactNode;
  extendedDateGroupingSelection?: React.ReactNode;
}

const DateRangePanel: React.FC<DateRangePanelProps> = ({
  renderSelectedDate,
  isActiveButton,
  handleDateRangeButtons,
  isDisabled,
  dateGroupingMode,
  filterBase,
  active_range_date,
  matches,
  datePicker,
  hourPicker,
}) => {
  const onDateRangeChange = (val: ISegmentControlOption) => {
    if (val.id !== CONSTANT.DATE_RANGE_TYPES.CUSTOM.type) {
      handleDateRangeButtons(val.id.toString());
      setDatePickerOpen(false);
    } else {
      setDatePickerOpen(true);
    }
  };

  const dateRangeOptionsWithDisability = () => {
    return dateRangeOptions.map((o: ISegmentControlOption) => {
      let disabled = false;
      if (
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH ||
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER
      ) {
        disabled =
          o.id === CONSTANT.DATE_RANGE_TYPES.YESTARDAY.type ||
          o.id === CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type ||
          o.id === CONSTANT.DATE_RANGE_TYPES.MTD.type;
      }
      if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.HOUR)
        disabled =
          o.id === CONSTANT.DATE_RANGE_TYPES.MTD.type ||
          o.id === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.type ||
          o.id === CONSTANT.DATE_RANGE_TYPES.YTD.type ||
          o.id === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.type;
      return { ...o, isDisabled: disabled };
    });
  };
  const { partnersSelected } = useSelector((state: RootState) => state.role);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [activeDateRangeOption, setActiveDateRangeOption] = useState<ISegmentControlOption>(dateRangeOptions[1]);
  useEffect(() => {
    setActiveDateRangeOption({
      id: getTypeByValue(Number(active_range_date)),
      name: dateRangeOptions.find((o) => o.id === getTypeByValue(Number(active_range_date)))?.name || "",
    });
  }, [active_range_date]);

  return (
    <Box
      sx={{
        margin: "1rem",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "18px",
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", gap: "1rem" }}>
        <SegmentControl
          options={dateRangeOptionsWithDisability()}
          value={activeDateRangeOption}
          onChange={onDateRangeChange}
          disabled={!(partnersSelected?.length > 0)}
        />
        <DatePicker isOpen={datePickerOpen} setOpenDate={setDatePickerOpen} />
        <GroupingMenu />
      </Box>
      <Box
        id="date-range-selected-date"
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginRight: "1rem",
        }}
      >
        <DateRangeIcon style={{ color: colors.textBaseTertiary }} />
        <Typography color={colors.textBaseTertiary} variant="bodyMedium">
          {renderSelectedDate({ isShortFormat: false })}
        </Typography>
      </Box>
    </Box>
  );
};

export default DateRangePanel;
