import { useEffect, useState, ChangeEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, TextField, Typography, Button, CircularProgress, useMediaQuery } from "@mui/material";

import {
  formContainer,
  loginContainer,
  logoContainer,
  titleSx,
  subtitleSx,
  mainBackgroundContainer,
  formWrapper,
  campaignReviewSpan,
} from "./styles";
import logo from "../../assets/images/logo-negative.svg";
import award2024 from "../../assets/images/award2024.svg";
import award2023 from "../../assets/images/award2023.svg";
import Splashscreen from "../../components/Splashscreen/Splashscreen";
import { colors } from "../../theme";
import { getReactAppVersion } from "../../auth/auth-api";
import { checkVersion } from "../../infrastructure/infrastructureHelpers";
import { CONSTANT } from "../../constants/constants";
import { useAuth0 } from "@auth0/auth0-react";
import auth from "../../auth/auth-helper";

const Maintenance: React.FC = () => {
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    auth.clearJWT(() => {});
    if (isAuthenticated) logout({ logoutParams: { returnTo: CONSTANT.auth0.maintenence_uri, federated: true } });
    const interval = setInterval(() => {
      getReactAppVersion().then((res) => {
        const newVersion = res?.reactAppVersion;
        if (newVersion && +newVersion !== CONSTANT.REACT_APP_VERSION_MAINTENANCE) {
          navigate("/login");
        }
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [isAuthenticated]);
  return (
    <Box sx={loginContainer}>
      <Box sx={formWrapper}>
        <Box sx={{ ...formContainer, display: "flex", flexFlow: "column" }}>
          <Box sx={logoContainer}>
            <Link to="/" onClick={(e) => e.preventDefault()}>
              <img src={logo} alt="logo" />
            </Link>
          </Box>
          <Box>
            <Typography sx={titleSx}>We’ll back soon!</Typography>
            <>
              <Typography sx={{ ...subtitleSx, mb: "1.65rem" }}>Sorry for the inconvinience!</Typography>
              <Typography sx={subtitleSx}>The system is currently under maintenance.</Typography>
            </>
          </Box>
        </Box>
      </Box>
      <Box sx={mainBackgroundContainer}>
        <Splashscreen />
        <Box sx={{ ...campaignReviewSpan, display: "flex", flexDirection: "column" }}>
          <Typography variant="bodyXLargeRegular" sx={{ color: colors.text02 }}>
            monitoring
          </Typography>
          <Typography variant="xxLarge800">Bid Enhancement</Typography>
          <Box sx={{ marginTop: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={award2024}
                  alt="award_1"
                  style={{
                    zIndex: 100,
                  }}
                />
                <img
                  src={award2023}
                  alt="award_2"
                  style={{
                    marginLeft: "-15px",
                    zIndex: 50,
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  padding: "16px",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "40px",
                  background: colors.grayBlack,
                }}
              >
                <img
                  src={logo}
                  alt="logo_award"
                  style={{
                    width: "86px",
                    height: "22px",
                  }}
                />
                <Typography
                  variant="body2XSmall"
                  sx={{
                    fontFamily: "Inter",
                    display: "flex",
                    width: "164px",
                    height: "38px",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: colors.mainWhite,
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}
                >
                  Best identity technology 2023 & 2024
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Maintenance;
