import { Box } from "@mui/material";
import { BasicTooltip } from "../../../../../components/BasicTooltip/BasicTooltip";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { convertLocal } from "../../../../../utils";
import { colors } from "../../../../../theme";

export const COLORS = ["#1BB2FF", "#204060", "#00cccc", "#FF8042", "#f06292", "orchid", "darkred", "lightblue"];
export const RADIAN = Math.PI / 180;

export const style = {
  listItem: {
    whiteSpace: "nowrap",
    marginBottom: "5px",
  },
  listItemIcon: {
    minWidth: "auto",
    margin: 0,
  },
  listItemText: {
    fontSize: "12px",
    color: colors.text01,
  },
};

export const styleLegend = {
  height: "auto",
  maxHeight: "300px",
  maxWidth: "150px",
  width: "150px",
  alignContent: "center",
  padding: "10px",
  overflow: "scroll",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflowX: "hidden",
};

export const renderListLegend = (data, operatingName = "name", operatingVal = "val", isPercent = true) => {
  // let filtered = data.filter((i) => i.value !== 0);
  return data.map((item, i) => {
    let str = "";
    let strTitle = "";
    let color = "transparent";
    if (+item[operatingVal] !== 0) {
      str = `${item[operatingName]}`;
      strTitle = `${item[operatingName]} (${
        isPercent ? Number(item[operatingVal]).toFixed(2) + "%" : convertLocal(Number(item[operatingVal]))
      })`;
      color = item.color;
    }
    return (
      <BasicTooltip
        key={`sec_piechart_tt_${i}`}
        tooltipText={strTitle}
        customStyles={{ fontSize: "12px" }}
        delay={500}
      >
        <Box key={`sec_pc_item_${i}`} sx={{ ...style.listItem, display: "flex", cursor: "default" }}>
          <Box sx={style.listItemIcon}>
            <FiberManualRecordIcon style={{ fill: color, fontSize: "0.75rem", marginRight: "5px" }} />
          </Box>
          <Box id="pie-chart-legend-item" sx={style.listItemText}>
            {str}
          </Box>
        </Box>
      </BasicTooltip>
    );
  });
};
