import { colors } from "../../theme";

interface IconProps {
  color?: string;
}

const DoubleArrowLeftIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color ?? colors.brandDefault;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.52051 9.99993L9.74967 13.2499C9.90245 13.4027 9.98231 13.5937 9.98926 13.8228C9.9962 14.052 9.91634 14.2499 9.74967 14.4166C9.5969 14.5694 9.40245 14.6458 9.16634 14.6458C8.93023 14.6458 8.73579 14.5694 8.58301 14.4166L4.74967 10.5833C4.66634 10.4999 4.60731 10.4097 4.57259 10.3124C4.53787 10.2152 4.52051 10.111 4.52051 9.99993C4.52051 9.88882 4.53787 9.78465 4.57259 9.68743C4.60731 9.59021 4.66634 9.49993 4.74967 9.4166L8.58301 5.58326C8.73579 5.43048 8.92676 5.35062 9.15592 5.34368C9.38509 5.33673 9.58301 5.4166 9.74967 5.58326C9.90245 5.73604 9.97884 5.93048 9.97884 6.1666C9.97884 6.40271 9.90245 6.59715 9.74967 6.74993L6.52051 9.99993ZM12.0205 9.99993L15.2497 13.2499C15.4025 13.4027 15.4823 13.5937 15.4893 13.8228C15.4962 14.052 15.4163 14.2499 15.2497 14.4166C15.0969 14.5694 14.9025 14.6458 14.6663 14.6458C14.4302 14.6458 14.2358 14.5694 14.083 14.4166L10.2497 10.5833C10.1663 10.4999 10.1073 10.4097 10.0726 10.3124C10.0379 10.2152 10.0205 10.111 10.0205 9.99993C10.0205 9.88882 10.0379 9.78465 10.0726 9.68743C10.1073 9.59021 10.1663 9.49993 10.2497 9.4166L14.083 5.58326C14.2358 5.43048 14.4268 5.35062 14.6559 5.34368C14.8851 5.33673 15.083 5.4166 15.2497 5.58326C15.4025 5.73604 15.4788 5.93048 15.4788 6.1666C15.4788 6.40271 15.4025 6.59715 15.2497 6.74993L12.0205 9.99993Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default DoubleArrowLeftIcon;
