import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import auth from "../auth/auth-helper";
import { CONSTANT } from "../constants/constants";
import { checkVersion } from "../infrastructure/infrastructureHelpers";
const host = CONSTANT.path.host;

const initialState = {
  partnersList: [],
  partnersSelected: [],
  prtnrName: "",
  sideBarIsOpen: false,
  status: "idle",
  userMultiselectGroups: null,
  currentMultiselectGroup: null,
  isEditingMultiselectGroup: false,
  isCreatingMultiselectGroup: false,
  isSelectAllSelected: false,
  isReportingPartnersSelected: false,
  userManagementAdminPermission: undefined,
  canAdmin: 0,
  canUseAdminElementsProcesing: false,
  imgTitle: false,
};

export const getPartnersList = createAsyncThunk(
  "report/getPartnersList",
  async (report, { rejectWithValue, dispatch, getState }) => {
    let { signal } = report;
    const state = getState();
    const token = state.users.loggedUser.token;
    try {
      let response = await fetch(`${host}/partners`, {
        // let response = await fetch(`${host}/users/list`, {
        // let response = await fetch(`${host}/partners`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
        signal,
      });
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Unauthorized");
        } else {
          throw new Error("Server can not get the list");
        }
      }
      let result = await response.json();
      checkVersion(result.reactAppVersion);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPartnersListType = (report) => getPartnersList(report);

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateIsEditingMultiselectGroup(state, action) {
      state.isEditingMultiselectGroup = action.payload;
    },

    checkedPartderById(state, action) {
      state.partnersList.forEach((p, i) => {
        if (p.id === action.payload) {
          state.partnersList[i].isChecked = !p.isChecked;
        }
      });
    },
    setUserManagementAdminPermission(state, action) {
      state.userManagementAdminPermission = action.payload;
    },
    updateUserManagementAdminPermission(state, action) {
      if (state.userManagementAdminPermission === undefined) {
        const userManagementAdminPermission = auth.getUserManagementModuleAdminPermission();
        state.userManagementAdminPermission = userManagementAdminPermission;
      }
    },
    setSideBarIsOpen(state, action) {
      state.sideBarIsOpen = action.payload;
    },

    setParnterName(state, action) {
      state.prtnrName = action.payload;
    },
    resetPartnersList(state, action) {
      state.partnersList = [];
    },
    resetMultiselectGroup(state) {
      state.currentMultiselectGroup = null;
    },
    updateIsCreatingMultiselectGroup(state, action) {
      state.isCreatingMultiselectGroup = action.payload;
    },
    updateIsSelectAllSelected(state, action) {
      state.isSelectAllSelected = action.payload;
      if (action.payload)
        state.partnersList = state.partnersList.map((p) => {
          return { ...p, isSelected: true };
        });
      else
        state.partnersList = state.partnersList.map((p) => {
          return { ...p, isSelected: false };
        });
    },
    setIsReportingPartnersSelected(state, action) {
      state.isReportingPartnersSelected = action.payload;
    },
    updImgTitle(state, action) {
      state.imgTitle = !state.imgTitle;
    },
    setCurrentMultiselectGroup(state, action) {
      state.currentMultiselectGroup = action.payload;
      if (action.payload) {
        state.partnersSelected = [];
        state.partnersList.forEach((pl, i) => {
          if (action.payload.partners.some((p) => p.id === pl.dummy_id)) {
            state.partnersSelected.push({ ...pl, isSelected: true, partner_name: pl.name });
            state.partnersList[i] = { ...pl, isSelected: true, partner_name: pl.name };
          } else {
            state.partnersList[i] = { ...pl, isSelected: false, partner_name: pl.name };
          }
        });
      }
    },
    resetRolesState(state) {
      return {
        ...initialState,
      };
    },
    setPartnersList(state, action) {
      state.partnersList = action.payload;
    },
    applyPartners(state, action) {
      state.partnersSelected = action.payload.map((p) => {
        return { ...p, isSelected: true };
      });
      state.partnersList = state.partnersList.map((p) => {
        return { ...p, isSelected: action.payload.some((pp) => pp.dummy_id === p.dummy_id) };
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPartnersList.pending, (state, action) => {
        state.status = "loading";
        state.processing = true;
      })
      .addCase(getPartnersList.fulfilled, (state, action) => {
        state.partnersSelected = [];
        state.processing = false;
        state.status = "succeeded";
        let partnersListSorted = action.payload.data.sort((a, b) => a.partner_name.localeCompare(b.partner_name));
        for (let index = 0; index < partnersListSorted.length; index++) {
          partnersListSorted[index] = {
            ...partnersListSorted[index],
            id: partnersListSorted[index].dummy_id,
            name: partnersListSorted[index].partner_name,
            isSelected: partnersListSorted[index].isSelected,
          };
          if (partnersListSorted[index].isSelected) state.partnersSelected.push(partnersListSorted[index]);
        }
        if (partnersListSorted.length === 1) {
          partnersListSorted[0].isSelected = true;
          state.partnersSelected = [partnersListSorted[0]];
        }
        state.partnersList = partnersListSorted;
      })
      .addCase(getPartnersList.rejected, (state, action) => {
        if (action.payload === "report not found") {
          state.status = "not_found";
          state.error = action.payload;
        } else {
          state.processing = false;
          state.status = "failed";
          state.error = action.payload;
        }
      });
  },
});

export const {
  setSideBarIsOpen,
  resetPartnersList,
  setParnterName,
  setSelectedPartnerArray,
  setCurrentMultiselectGroup,
  resetMultiselectGroup,
  updateIsCreatingMultiselectGroup,
  updateIsEditingMultiselectGroup,
  updateIsSelectAllSelected,
  setIsReportingPartnersSelected,
  setUserManagementAdminPermission,
  updateUserManagementAdminPermission,
  resetRolesState,
  updImgTitle,
  setPartnersList,
  setPartnersChecked,
  checkedPartderById,
  applyPartners,
} = roleSlice.actions;

export default roleSlice.reducer;
